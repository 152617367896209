import * as React from "react";
import {useEffect} from "react";
import {useIntl} from "react-intl";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod} from "common/services/period";
import {Dash} from "@fleet/common/constants/dash";
import {formatNumber} from "@fleet/common/utils/numberFormatUtils";

import {useCompanyEarningsApi} from "../hooks/useCompanyEarningsApi";
import {StatCard} from "./StatCard";

interface Props {
    period: ApiPeriod;
}

export const EarningsCardsRow: React.FC<Props> = ({period}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const companyEarningsApi = useCompanyEarningsApi(period);
    const {data, status, fetch} = useFetch(companyEarningsApi);

    useEffect(() => {
        fetch?.({period});
    }, [fetch, period]);

    const currency = data?.currency_symbol ?? "";
    const sumPerHourUnit = `${currency}/${i18n("common.hour_abbreviation")}`;

    const grossEarnings = `${i18n("auth.app.fleet.reports.header.gross_revenue")}, ${currency}`;
    const expenses = `${i18n("auth.app.fleet.reports.cards.expenses")}, ${currency}`;
    const netEarnings = `${i18n("auth.app.fleet.reports.cards.net_earnings")}, ${currency}`;
    const bonuses = `${i18n("auth.app.fleet.reports.cards.bonuses")}, ${currency}`;
    const compensations = `${i18n("auth.app.fleet.reports.compensations")}, ${currency}`;
    const ephGross = `${i18n("auth.app.fleet.reports.cards.eph_gross")}, ${sumPerHourUnit}`;
    const ephNet = `${i18n("auth.app.fleet.reports.cards.eph_net")}, ${sumPerHourUnit}`;

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(status);

    return (
        <div className="flex w-auto flex-nowrap gap-4 overflow-auto sm:min-h-[7rem] sm:flex-wrap">
            <StatCard
                title={grossEarnings}
                mainValue={formatNumber(intl, data?.gross_revenue_total, {zeroValue: Dash.EN, emptyValue: Dash.EN})}
                isLoading={isLoading}
            />
            <StatCard
                title={expenses}
                mainValue={formatNumber(intl, data?.total_expenses, {zeroValue: Dash.EN, emptyValue: Dash.EN})}
                isLoading={isLoading}
            />
            <StatCard
                title={netEarnings}
                mainValue={formatNumber(intl, data?.net_earnings, {zeroValue: Dash.EN, emptyValue: Dash.EN})}
                isLoading={isLoading}
            />
            <StatCard
                title={bonuses}
                mainValue={formatNumber(intl, data?.bonuses, {zeroValue: Dash.EN, emptyValue: Dash.EN})}
                isLoading={isLoading}
            />
            <StatCard
                title={compensations}
                mainValue={formatNumber(intl, data?.compensations, {zeroValue: Dash.EN, emptyValue: Dash.EN})}
                isLoading={isLoading}
            />
            <StatCard
                title={ephGross}
                mainValue={formatNumber(intl, data?.earnings_per_hour_gross, {zeroValue: Dash.EN, emptyValue: Dash.EN})}
                isLoading={isLoading}
            />
            <StatCard
                title={ephNet}
                mainValue={formatNumber(intl, data?.earnings_per_hour_net, {zeroValue: Dash.EN, emptyValue: Dash.EN})}
                isLoading={isLoading}
            />
        </div>
    );
};
