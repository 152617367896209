import {useEffect} from "react";
import {generatePath, Navigate, useParams} from "react-router-dom";

import LoadingSpinner from "common/components/spinner";
import {CommonRoute, FleetRoute} from "common/constants/routes";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {FleetInboxService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const getSmsDataFunction = (api: Api, body: FleetInboxService.GetSmsNotificationRequest) =>
    api.fleetInbox.fleetInboxSmsGetNotification(body);

const SmsNotificationRedirect = () => {
    const params = useParams();

    const {getHomePath} = useAbsolutePath();
    const notificationId = Number(params.id);

    const {fetch, data, status} = useFetch(getSmsDataFunction);

    useEffect(() => {
        if (fetch && notificationId) {
            fetch({id: notificationId});
        }
    }, [fetch, notificationId]);

    const isLoading = status === FetchStatus.Init || status === FetchStatus.Loading;

    if (isLoading) {
        return <LoadingSpinner show />;
    }

    if (!data) {
        return <Navigate to={getHomePath()} />;
    }

    const generateRedirectPath = (path: FleetRoute.DRIVER | FleetRoute.VEHICLE) =>
        `${generatePath(`${CommonRoute.HOME}${data.company_id}/${path}`, {
            id: String(data.entity_id),
        })}?origin=sms&medium=notification&id=${notificationId}`;

    if (data.message_type === FleetInboxService.MessageType.DRIVER_BLOCK && data.entity_id) {
        return <Navigate replace to={generateRedirectPath(FleetRoute.DRIVER)} />;
    }

    if (data.message_type === FleetInboxService.MessageType.VEHICLE_BLOCK && data.entity_id) {
        return <Navigate replace to={generateRedirectPath(FleetRoute.VEHICLE)} />;
    }

    return <Navigate replace to={`${getHomePath()}${data.company_id}`} />;
};

export default SmsNotificationRedirect;
