import {FC, useCallback, useEffect} from "react";
import {useIntl} from "react-intl";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";
import {getDatetimeString} from "@fleet/common/utils/dateFormatUtils";

import {Button, Dialog} from "@bolteu/kalep-react";

const fetchFunction = (api: Api) => api.carRentalPayment.carRentalPaymentDeleteUploadedPayments();

export interface ConfirmDeletionModalProps {
    show: boolean;
    closeModal: () => void;
    setPaymentsRemoved: () => void;
    currentPeriodUploadTimestamp: number;
}

const ConfirmDeletionDialog: FC<ConfirmDeletionModalProps> = ({
    show,
    closeModal,
    setPaymentsRemoved,
    currentPeriodUploadTimestamp,
}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const {status, fetch} = useFetch(fetchFunction);

    const confirmUploadedPaymentsDeletion = useCallback(async () => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            setPaymentsRemoved();
        } else if (status === FetchStatus.Error) {
            closeModal();
        }
    }, [status, setPaymentsRemoved, closeModal]);

    return (
        <ResponsiveDialog
            title={i18n("auth.app.fleet.car-rentals.confirm_delete_dialog.title")}
            isOpen={show}
            onRequestClose={closeModal}
        >
            <Dialog.Content>
                <div className="text-base text-neutral-700">
                    {i18n("auth.app.fleet.car-rentals.confirm_delete_dialog.description", {
                        dateTime: getDatetimeString(intl, currentPeriodUploadTimestamp),
                    })}
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={closeModal}>
                        {i18n("auth.app.fleet.car-rentals.confirm_delete_dialog.cancel")}
                    </Button>
                    <Button variant="danger" onClick={confirmUploadedPaymentsDeletion}>
                        {i18n("auth.app.fleet.car-rentals.confirm_delete_dialog.delete")}
                    </Button>
                </div>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};

export default ConfirmDeletionDialog;
