import {FC, useContext} from "react";

import TopLeftCornerNotification from "@fleet/common/components/TopLeftCornerNotification";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";
import {NotificationContext} from "@fleet/common/services/notificationProvider";
import {useId} from "@react-aria/utils";

import {BaseModal, Dialog, DialogProps, DrawerProps} from "@bolteu/kalep-react";

export interface MobileDialogProps extends DrawerProps {
    variant?: "default" | "alert";
}

const MobileDialog: FC<MobileDialogProps> = ({
    isOpen,
    title,
    onRequestClose,
    children,
    variant = "default",
}: MobileDialogProps) => {
    const {notification, setNotification} = useContext(NotificationContext);
    const titleId = useId();

    return (
        <BaseModal isOpen={isOpen} onRequestClose={onRequestClose} aria-labelledby={titleId} placement="m-0">
            {notification !== null && (
                <TopLeftCornerNotification
                    key="base_modal_notification"
                    notification={notification}
                    setNotification={setNotification}
                />
            )}
            <div className="bg-layer-floor-1 w-dvh h-dvh flex flex-col">
                <BaseModal.Header title={title} titleId={titleId}>
                    {variant === "default" && <BaseModal.Close onClose={onRequestClose} />}
                </BaseModal.Header>
                {children}
            </div>
        </BaseModal>
    );
};

export const ResponsiveDialog = (props: DialogProps) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return isMobileView ? <MobileDialog {...props} /> : <Dialog {...props} />;
};
