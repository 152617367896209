import {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {EventName, EventPropertiesDownloadType} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, filePeriod, getPeriodName, Period} from "common/services/period";
import {cleanStringForFileName, downloadBase64File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {DriverSessionHistoryService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import DownloadSessionsRequest = DriverSessionHistoryService.DownloadSessionsRequest;

const downloadSessionsFetch = (api: Api, body: DownloadSessionsRequest) =>
    api.driverSessionHistory.downloadSessions(body);

interface Props {
    period: ApiPeriod;
    driverIds: number[];
}

const DownloadActityLog = ({period, driverIds}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const account = useContext(AccountContextProvider);
    const [base64File, setBase64File] = useState<string | null>(null);
    const {data: downloadedShift, fetch: downloadsShift, status: downloadShiftStatus} = useFetch(downloadSessionsFetch);
    const {trackEvent} = useTracking();

    useEffect(() => {
        if (downloadShiftStatus === FetchStatus.Success && downloadedShift.file) {
            setBase64File(downloadedShift.file);
        }
    }, [downloadShiftStatus, downloadedShift]);

    useEffect(() => {
        const companyName = account.selectedCompany?.company.name;
        if (base64File && companyName) {
            const periodStr = filePeriod(period, intl.locale);
            const cleanCompanyName = cleanStringForFileName(companyName);
            const fileName = `${i18n(
                "auth.app.fleet.shifts.activity_log.csv_file_title",
            )}-${periodStr}-${cleanCompanyName}`;
            downloadBase64File(base64File, MimeTypes.CSV, fileName);
            setBase64File(null);
            trackEvent(EventName.FileDownloaded, {
                downloadType: EventPropertiesDownloadType.ShiftActivityLogCsv,
                periodName: getPeriodName(period),
                period: periodStr,
            });
        }
    }, [account.selectedCompany, base64File, intl, i18n, period, trackEvent]);

    const downloadCsv = useCallback(() => {
        if (downloadsShift) {
            const dates =
                period.period === Period.CUSTOM
                    ? {start: period.startDate, end: period.endDate}
                    : apiPeriodToDates(period);
            downloadsShift({
                start_date: getIsoDate(dates.start),
                end_date: getIsoDate(dates.end),
                driver_ids: driverIds,
            });
        }
    }, [downloadsShift, driverIds, period]);

    return (
        <Button variant="secondary" onClick={downloadCsv} loading={downloadShiftStatus === FetchStatus.Loading}>
            {i18n("common.download")}
        </Button>
    );
};

export default DownloadActityLog;
