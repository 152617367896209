import * as React from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {Dash} from "@fleet/common/constants/dash";
import {formatNumber} from "@fleet/common/utils/numberFormatUtils";

import {Chip, Skeleton} from "@bolteu/kalep-react";

interface Props {
    title: string;
    value: number;
    currencySymbol: string;
    subText: React.ReactNode;
    isLoading: boolean;
}

const BalanceStatCard: React.FC<Props> = ({title, value, currencySymbol, subText, isLoading}) => {
    const intl = useIntl();
    const {i18n} = useI18n();

    const isNegative = value < 0;

    const formattedValue = formatNumber(intl, value, {minFractionDigits: 2, maxFractionDigits: 2}).replace(
        Dash.HYPHEN,
        Dash.EN,
    );

    const formattedValueWithCurrency = `${formattedValue} ${currencySymbol}`;
    const valueTextColor = isNegative ? "text-danger-primary" : "text-primary";

    let Value: React.FC = () => (
        <div className={`text-2xl font-semibold ${valueTextColor}`}>{formattedValueWithCurrency}</div>
    );

    if (isLoading) {
        Value = () => (
            <div className="pt-3">
                <Skeleton variant="rounded" width={96} height={28} />
            </div>
        );
    }

    const shouldShowDebtChip = isNegative && !isLoading;
    const shouldShowSubText = !isLoading;

    return (
        <div className="flex h-[104px] min-w-[210px] flex-col justify-between gap-0.5 rounded-lg border border-neutral-400 p-4 md:h-[128px] md:w-[280px]">
            <div>
                <div className="flex items-center justify-between gap-2">
                    <div className="text-secondary">{title}</div>
                    {shouldShowDebtChip && (
                        <Chip appearance="danger" size="sm" label={i18n("auth.app.fleet.balance.cards_row.debt")} />
                    )}
                </div>
                <Value />
            </div>
            {shouldShowSubText && <div className="text-secondary text-xs">{subText}</div>}
        </div>
    );
};

export default BalanceStatCard;
