import {useCallback, useEffect, useMemo} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {debounce} from "lodash-es";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox, SelectOption} from "@bolteu/kalep-react";

import {useAssigmentDrivers} from "../hooks/useAssigmentDrivers";

interface Props {
    orderId: number;
    selectedDriverId?: number | null;
    selectedDriverName?: string;
    onChange: (driverId?: number, driverName?: string) => void;
    validationError?: string;
}

const getDriversForScheduledRideAssignmentFunction = (
    api: Api,
    body: FleetPortalOrderService.GetDriversForScheduledRideAssignment,
) => api.fleetPortalOrder.getDriversForScheduledRideAssignment(body);

export const DriverComboBoxForOrderAssignment = ({
    orderId,
    selectedDriverId,
    selectedDriverName,
    onChange,
    validationError,
}: Props) => {
    const {i18n} = useI18n();
    const {fetch: getDriversFetch, data, status} = useFetch(getDriversForScheduledRideAssignmentFunction);

    const {onDriverChange, driversOptions, searchValue, setSearchValue} = useAssigmentDrivers({
        fetchData: data,
        fetchStatus: status,
        selectedDriverName,
        onChange,
    });

    const getDrivers = useCallback(
        (search: string) => {
            if (!getDriversFetch) {
                return;
            }
            getDriversFetch({order_id: orderId, search});
        },
        [getDriversFetch, orderId],
    );

    const getDriversDebounce = useMemo(() => debounce((newSearch: string) => getDrivers(newSearch), 500), [getDrivers]);

    useEffect(() => {
        if (getDrivers) {
            getDrivers("");
        }
    }, [getDrivers]);

    const onSearchValueChange = useCallback(
        (event) => {
            setSearchValue(event.target.value);
            getDriversDebounce(event.target.value);
        },
        [getDriversDebounce, setSearchValue],
    );

    // No filtering needed as we are getting drivers by search from API
    const filterOptions = useCallback((options: SelectOption[]) => {
        return options;
    }, []);

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(status);
    const selectedDriverOption = driversOptions?.find((option) => option.value === selectedDriverId);

    return (
        <ComboBox
            placeholder={i18n("auth.app.orders.scheduled_rides.select-driver-placeholder")}
            value={selectedDriverOption}
            options={driversOptions ?? []}
            onChange={onDriverChange}
            inputValue={searchValue}
            onInputChange={onSearchValueChange}
            fullWidth
            loading={isLoading}
            error={Boolean(validationError)}
            helperText={validationError}
            filterOptions={filterOptions}
        />
    );
};
