import {useContext} from "react";

import {isActiveOrBlockedFleet} from "common/constants/types";
import {AccountContextProvider} from "features/account/accountStateProvider";

export const useCompanyData = () => {
    const profile = useContext(AccountContextProvider)?.profile;

    const continueCompanyRegistrationHash = profile?.companyRegistrationHash;
    const availableFleets = profile?.fleets ?? [];
    const availableHoldingFleets = profile?.holdingFleets ?? [];
    const availableActiveFleets = availableFleets.filter((f) => isActiveOrBlockedFleet(f));

    const isZeroCompanies = !availableFleets.length && !availableHoldingFleets.length;
    const isOnlyCompanyRegistrations = !availableActiveFleets.length && !availableHoldingFleets.length;

    return {
        isZeroCompanies,
        isOnlyCompanyRegistrations,
        availableFleets,
        availableActiveFleets,
        availableHoldingFleets,
        continueCompanyRegistrationHash,
    };
};
