import {useMemo} from "react";

import DataAndMeasuresCell from "common/components/table/DataAndMeasuresCell";
import {useI18n} from "common/hooks/useI18n";
import {Dash} from "@fleet/common/constants/dash";

import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import TableActions from "../components/TableActions";
import {HoldingFleetInvoicesTableData} from "./useInvoices";

export function useTableColumns(handleInvoiceDetailsClick: (item: HoldingFleetInvoicesTableData) => () => void) {
    const {i18n} = useI18n();

    return useMemo<TableHeaders<HoldingFleetInvoicesTableData>>(() => {
        return {
            public_id: {
                label: i18n("common.invoice_number"),
                renderCell: (item: HoldingFleetInvoicesTableData) => (
                    <DefaultCellContent compact>
                        <div className="flex h-full w-full flex-col justify-center">
                            <div className="text-primary text-sm antialiased">{item.public_id}</div>
                            <div className="text-secondary whitespace-nowrap text-xs antialiased">
                                {`${item.sub_fleets.length} ${i18n("common.companies")}`}
                            </div>
                        </div>
                    </DefaultCellContent>
                ),
            },
            date: {
                label: i18n("common.date"),
            },
            due_date: {
                label: i18n("common.due_date"),
                renderCell: (item: HoldingFleetInvoicesTableData) => (
                    <DefaultCellContent className={item.due_date === Dash.EM ? "text-content-tertiary" : ""}>
                        {item.due_date}
                    </DefaultCellContent>
                ),
            },
            total_sum: {
                label: i18n("common.total_sum"),
                renderCell: (item: HoldingFleetInvoicesTableData) => (
                    <DataAndMeasuresCell data={item.total_sum} measure={item.currency} />
                ),
            },
            id: {
                label: "",
                renderCell: (item: HoldingFleetInvoicesTableData) => (
                    <DefaultCellContent>
                        <TableActions item={item} onInvoiceDetailsClick={handleInvoiceDetailsClick} />
                    </DefaultCellContent>
                ),
            },
        };
    }, [handleInvoiceDetailsClick, i18n]);
}
