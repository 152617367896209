import {I18nFn} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {Address} from "./types";

export const getPaymentMethodLabel = (
    paymentMethod: FleetPortalOrderService.CreateOrderPaymentMethod,
    i18n: I18nFn,
) => {
    switch (paymentMethod) {
        case FleetPortalOrderService.CreateOrderPaymentMethod.CASH:
            return i18n("payment-methods.cash");
        case FleetPortalOrderService.CreateOrderPaymentMethod.CARD_TERMINAL:
            return i18n("payment-methods.card");
        default:
            return UnreachableCode.never(paymentMethod, "");
    }
};

export const getApiAddressFromAddress = (address: Address): FleetPortalOrderService.Address | null => {
    if (address.lat === undefined || address.lng === undefined) {
        return null;
    }
    return {
        address: address.full_address ?? address.address_name,
        lat: address.lat,
        lng: address.lng,
        place_id: address.place_id ?? undefined,
    };
};
