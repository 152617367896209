import {useCallback} from "react";
import {Link} from "react-router-dom";

import {CompanyOnboardingRoutePrefix} from "common/constants/routes";
import {
    Company,
    CompanyType,
    Fleet,
    HoldingFleet,
    isActiveOrBlockedFleet,
    isBlockedFleet,
    isFleet,
    isHoldingFleet,
} from "common/constants/types";
import {CompanySwitchType, useCompanySelection} from "common/hooks/useCompanySelection";
import {useI18n} from "common/hooks/useI18n";
import {SelectedCompany} from "features/account/accountStateProvider";

import {FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip, Typography} from "@bolteu/kalep-react";
import {ChipSize} from "@bolteu/kalep-react/build/components/Chip/Chip.types";

import CompanyList from "../CompanyList";

const useCompanySelector = () => {
    const {i18n} = useI18n();
    const onSelectCompany = useCompanySelection();

    const getDescription = (
        company: Company | undefined,
        isListItem: boolean,
        isSideBar?: boolean,
        onApplicationLinkClick?: () => void,
    ): React.ReactNode | undefined => {
        if (isFleet(company)) {
            if (!isActiveOrBlockedFleet(company)) {
                const cityAndSeparator: string = company.city ? `${company.city} • ` : "";
                if (isListItem || isSideBar) {
                    return `${cityAndSeparator}${i18n("common.application")}`;
                }
                return (
                    <>
                        {cityAndSeparator}
                        <Link
                            className="text-action-primary font-semibold"
                            to={`/${CompanyOnboardingRoutePrefix}/${company.id}`}
                            onClick={onApplicationLinkClick}
                        >
                            {i18n("common.application")}
                        </Link>
                    </>
                );
            }
            return company.city ?? undefined;
        }
        if (isHoldingFleet(company) && isListItem) {
            return `${company.sub_fleets.length} ${i18n("company-selector.subsidiaries")}`;
        }
        return undefined;
    };

    const getCompanyName = (company: Company | undefined): string | undefined => {
        if (isFleet(company)) {
            return company.company_name ?? company.name;
        }
        return company?.name;
    };

    const handleCompanySelection = useCallback(
        (company: Company, companyType: CompanyType) => () => {
            onSelectCompany(company, companyType, CompanySwitchType.DEFAULT);
        },
        [onSelectCompany],
    );

    const areCompanySettingsShown = (company: Company | undefined) => {
        return isActiveOrBlockedFleet(company);
    };

    const isChipShownInHeader = (company: Company | undefined) => {
        return isBlockedFleet(company);
    };

    const getStatusChip = (status: FleetOwnerService.ExpandedCompanyStatus, size: ChipSize = "sm") => {
        switch (status) {
            case FleetOwnerService.ExpandedCompanyStatus.ACTIVE:
                return null;
            case FleetOwnerService.ExpandedCompanyStatus.ACTIVE_ONBOARDING:
                return null;
            case FleetOwnerService.ExpandedCompanyStatus.BLOCKED:
                return <Chip label={i18n("common.statuses.blocked")} appearance="danger" size={size} />;
            case FleetOwnerService.ExpandedCompanyStatus.DRAFT:
                return (
                    <Chip label={i18n("company_registration.status_badges.draft")} appearance="neutral" size={size} />
                );
            case FleetOwnerService.ExpandedCompanyStatus.DECLINED:
                return (
                    <Chip label={i18n("company_registration.status_badges.declined")} appearance="danger" size={size} />
                );
            case FleetOwnerService.ExpandedCompanyStatus.INCOMPLETE:
                return (
                    <Chip
                        label={i18n("company_registration.status_badges.incomplete")}
                        appearance="danger"
                        size={size}
                    />
                );
            case FleetOwnerService.ExpandedCompanyStatus.IN_REVIEW:
                return (
                    <Chip label={i18n("company_registration.status_badges.in_review")} appearance="promo" size={size} />
                );
            default:
                return null;
        }
    };

    const renderCompanySelectionItems = (
        selectedCompany: SelectedCompany | null,
        fleets: Fleet[],
        holdingFleets: HoldingFleet[],
    ) => {
        const hasHoldingFleets = Boolean(holdingFleets.length);
        const hasFleets = Boolean(fleets.length);

        const nonSelectedFleets = fleets.filter((fleet) => fleet.id !== selectedCompany?.company.id);
        const nonSelectedHoldingFleets = holdingFleets.filter((fleet) => fleet.id !== selectedCompany?.company.id);

        const shouldShowHoldingFleetsHeader = Boolean(nonSelectedHoldingFleets.length);
        const shouldShowFleetsHeader = Boolean(nonSelectedFleets.length);

        const renderFleetList = (companies: Company[]) => {
            return <CompanyList companies={companies} onSelectCompany={handleCompanySelection} />;
        };

        return (
            <>
                {!hasHoldingFleets && renderFleetList(nonSelectedFleets)}
                {hasHoldingFleets && (
                    <>
                        {shouldShowHoldingFleetsHeader && (
                            <Typography variant="body-primary" fontSize="text-base" fontWeight="semibold">
                                <div className="p-4">{i18n("company-selector.holding_fleets")}</div>
                            </Typography>
                        )}
                        {renderFleetList(nonSelectedHoldingFleets)}
                        {hasFleets && (
                            <>
                                {shouldShowFleetsHeader && (
                                    <Typography variant="body-primary" fontSize="text-base" fontWeight="semibold">
                                        <div className="p-4">{i18n("company-selector.fleet_companies")}</div>
                                    </Typography>
                                )}
                                {renderFleetList(nonSelectedFleets)}
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    return {
        handleCompanySelection,
        getDescription,
        getCompanyName,
        getStatusChip,
        areCompanySettingsShown,
        isChipShownInHeader,
        renderCompanySelectionItems,
    };
};

export {useCompanySelector};
