import {useCallback, useRef} from "react";

import {Radio, RadioProps, TypographyStack} from "@bolteu/kalep-react";

export const CustomRadio = ({
    label,
    helperText,
    iconUrl,
    defaultIconUrl,
    ...props
}: RadioProps & {helperText?: string; iconUrl?: string; defaultIconUrl?: string}) => {
    const imgRef = useRef<HTMLImageElement>(null);

    const onIconUrlError = useCallback(() => {
        if (imgRef.current && defaultIconUrl) {
            imgRef.current.src = defaultIconUrl;
        }
    }, [defaultIconUrl, imgRef]);

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control -- guaranteed to have control element
        <label className="flex items-center gap-2">
            <Radio {...props} />
            {iconUrl && (
                <img
                    ref={imgRef}
                    src={iconUrl}
                    alt="Category"
                    className="min-h-[33px] w-[60px] object-contain"
                    onError={onIconUrlError}
                />
            )}

            <TypographyStack
                secondary={helperText}
                primaryTypographyProps={{fontWeight: !helperText ? "semibold" : "regular"}}
            >
                {label}
            </TypographyStack>
        </label>
    );
};
