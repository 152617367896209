import {useCallback, useContext, useEffect} from "react";

import {DocumentsAlert} from "common/components/document/DocumentsAlert";
import {DocumentsList} from "common/components/document/DocumentsList";
import {DocumentOwnerType, useDocumentsList} from "common/components/document/useDocumentsList";
import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetCompanyNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {FeaturesContextProvider} from "../../../../FeaturesProvider";
import {BlockAlert} from "./components/BlockAlert";
import BankingDetailsTab from "./components/tabs/BankingDetailsTab";
import CompanyDetailsTab from "./components/tabs/CompanyDetailsTab";
import TaxInfoTab from "./components/tabs/TaxInfoTab";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

export enum TabName {
    CompanyDetails = "company_details",
    BankingDetails = "banking_details",
    TaxInfo = "tax_info",
}

const getTinConfigFetchFunction = (api: Api, request: FleetCompanyNoAuthService.GetTinConfigRequest) => {
    return api.fleetCompanyNoAuth.taxIdentificationNumberGetCountryConfig(request);
};
const getCompanyDetailsFetchFunction = (api: Api) => api.fleetOwnerPortal.getCompanyDetails();

const getBlocksFetchFunction = (api: Api) => api.fleetOwnerPortal.getCompanyActiveBlocks();

const CompanySettingsPage = () => {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const isCompanyDocumentsEnabled = useContext(FeaturesContextProvider)?.company_documents || false;

    const {documentsListProps, documentsAlertsProps} = useDocumentsList({
        areDocumentsEnabled: isCompanyDocumentsEnabled,
        owner: {id: fleet?.id as number, type: DocumentOwnerType.Company},
        isUploadAllowed: true,
    });

    const {i18n} = useI18n();

    const {data, status, fetch} = useFetch(getCompanyDetailsFetchFunction);

    const {fetch: tinConfigFetch, data: tinConfig} = useFetch(getTinConfigFetchFunction);

    const {fetch: blockFetch, data: blocks} = useFetch(getBlocksFetchFunction);

    const tabs = useTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, TabName.CompanyDetails);

    useEffect(() => {
        const pageTitle = i18n("auth.app.company-settings.title_v2");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (blockFetch) {
            blockFetch({});
        }
    }, [blockFetch]);

    useEffect(() => {
        if (tinConfigFetch && fleet?.country) {
            tinConfigFetch({country: fleet.country});
        }
    }, [fleet?.country, tinConfigFetch]);

    const refreshData = useCallback(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    if (status === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    return (
        <Page>
            <div className="flex flex-row items-center justify-between">
                <ResponsiveHeader
                    type={ResponsiveHeaderType.MainPage}
                    text={fleet?.company_name ?? fleet?.name ?? ""}
                />
            </div>
            <div className="grid grid-cols-1 gap-y-4 md:grid-cols-12">
                <div className="col-span-7 flex flex-col gap-4">
                    {isCompanyDocumentsEnabled && documentsAlertsProps && (
                        <DocumentsAlert
                            expiredDocuments={documentsAlertsProps.expiredDocuments}
                            declinedDocuments={documentsAlertsProps.declinedDocuments}
                        />
                    )}
                    <BlockAlert blocks={blocks?.blocks ?? []} customerSupportEmail={data?.customer_support_email} />
                </div>
                <div className="col-span-12">
                    <TabSelector />
                </div>
                <div className="col-span-7">
                    {activeTab === TabName.CompanyDetails && <CompanyDetailsTab companyData={data} />}
                    {activeTab === TabName.BankingDetails && <BankingDetailsTab companyData={data} />}
                    {activeTab === TabName.TaxInfo && (
                        <TaxInfoTab
                            companyData={data}
                            refreshData={refreshData}
                            isShowTin={tinConfig?.is_enabled || false}
                        />
                    )}
                </div>
                <div className="col-span-4 col-end-[-1]">
                    {documentsListProps && <DocumentsList {...documentsListProps} />}
                </div>
            </div>
        </Page>
    );
};

export default CompanySettingsPage;
