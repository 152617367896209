import {createContext, useCallback, useContext, useRef, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ApiContextProvider} from "common/services/apiProvider";
import {isError} from "common/util/isErrorType";
import {TranslationKeys} from "config/translations";
import {getUnixTime} from "date-fns";
import {isEqual} from "lodash-es";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ServerError} from "./constants";
import {AddressWithLatLng, CreateDrawerType, Step1Data, Step2Data} from "./types";
import {getApiAddressFromAddress} from "./util";

type FindRideOptionsRequest =
    | FleetPortalOrderService.FindInstantRideOptions
    | FleetPortalOrderService.FindScheduledRideOptions;

type FindRideOptionsResult =
    | {type: "success"; data: FleetPortalOrderService.RideOptions}
    | {type: "error"; error: FleetPortalOrderService.ValidationError}
    | null;

type Step1DataPickupExists = Step1Data & {pickup: Step1Data["pickup"] & AddressWithLatLng};

export type CatetegoriesContext = {
    rideOptions: FleetPortalOrderService.RideOptions | null;
    resetRideOptions: () => void;
    findRideOptions: (
        formData: Step1DataPickupExists & Step2Data,
        drawerType: CreateDrawerType,
    ) => Promise<FindRideOptionsResult>;
    isLoading: boolean;
    apiError: FleetPortalOrderService.ValidationError | null;
};

const getRequest = (formData: Step1DataPickupExists & Step2Data, drawerType: CreateDrawerType) => {
    const hasNoDropoffs = formData.dropoffs.every((dropoff) => !dropoff.address_name);
    const dropoffs: FleetPortalOrderService.Address[] | undefined = hasNoDropoffs
        ? undefined
        : (formData.dropoffs
              .map((dropoff) => getApiAddressFromAddress(dropoff))
              .filter((address) => address !== null) as FleetPortalOrderService.Address[]);
    const pickup = getApiAddressFromAddress(formData.pickup);
    if (!pickup) {
        // Shouldnt happen as this should be checked before
        throw Error("Address is missing lat or lng");
    }
    let request: FindRideOptionsRequest = {
        type: FleetPortalOrderService.OrderCreationType.INSTANT,
        create_order_uuid: formData.create_order_uuid,
        pickup,
        dropoffs,
        payment_method: formData.payment_method,
    };
    if (drawerType === CreateDrawerType.SCHEDULE) {
        request = {
            ...request,
            type: FleetPortalOrderService.OrderCreationType.SCHEDULED,
            scheduled_for: getUnixTime(formData.scheduled_for),
        };
    }
    return request;
};

const getErrorTranslationKey = (error: Error) => {
    if (error.message.includes(ServerError.GEO_PROVIDER_ERROR)) {
        return ServerError.UNABLE_TO_RESOLVE_ADDRESSES;
    }
    if (error.message === ServerError.NO_RESULTS) {
        return ServerError.AREA_NOT_SERVICED;
    }
    return error.message;
};

const getErrorProperty = (errorMessage: string) => {
    switch (errorMessage) {
        case ServerError.AREA_NOT_SERVICED:
        case ServerError.UNABLE_TO_RESOLVE_ADDRESSES:
        case ServerError.INVALID_REQUEST_DISTANCE_TOO_LONG:
            return "dropoffs";
        case ServerError.NO_CATEGORY_MATCHING_TIME:
            return "scheduled_for";
        default:
            return "general";
    }
};

const MIN_MILLIS_BETWEEN_SAME_REQUEST_API_CALLS = 2_000;

const CategoriesContextProvider = createContext<CatetegoriesContext>({
    rideOptions: null,
    resetRideOptions: () => {},
    findRideOptions: () => {
        return new Promise((resolve) => {
            resolve(null);
        });
    },
    isLoading: false,
    apiError: null,
});
CategoriesContextProvider.displayName = "CategoriesContextProvider";

const CategoriesProvider = ({children}: {children: React.ReactNode}) => {
    const {i18n} = useI18n();
    const {setNotification} = useContext(NotificationContext);
    const lastFetch = useRef<{ms: number; request?: FindRideOptionsRequest}>({ms: 0});
    const api = useContext(ApiContextProvider);
    const [data, setData] = useState<FleetPortalOrderService.RideOptions | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState<FleetPortalOrderService.ValidationError | null>(null);

    const findRideOptions = useCallback(
        async (
            formData: Step1DataPickupExists & Step2Data,
            drawerType: CreateDrawerType,
        ): Promise<FindRideOptionsResult> => {
            const request = getRequest(formData, drawerType);
            if (
                !api ||
                (isEqual(request, lastFetch.current.request) &&
                    Date.now() - lastFetch.current.ms < MIN_MILLIS_BETWEEN_SAME_REQUEST_API_CALLS)
            ) {
                return null;
            }

            setIsLoading(true);
            try {
                const response = await api.fleetPortalOrder.findRideOptions(request);
                lastFetch.current.ms = Date.now();
                lastFetch.current.request = request;
                setData(response);
                setApiError(null);
                setIsLoading(false);
                return {type: "success", data: response};
            } catch (e) {
                const error = isError(e) ? getErrorTranslationKey(e) : "";
                const errorMsg = i18n(`api.error.${error}` as TranslationKeys, undefined, "api.default_error");
                const errorPropery = getErrorProperty(error);
                setApiError({property: errorPropery, error: errorMsg});
                if (errorPropery === "general") {
                    setNotification({
                        type: NotificationType.ERROR,
                        text: errorMsg,
                        timeout: 6000,
                    });
                }
                setIsLoading(false);
                return {type: "error", error: {property: errorPropery, error: errorMsg}};
            }
        },
        [api, i18n, setNotification],
    );

    const resetRideOptions = useCallback(() => {
        setData(null);
        setApiError(null);
        setIsLoading(false);
        lastFetch.current = {ms: 0};
    }, []);

    const contextValue: CatetegoriesContext = {
        rideOptions: data,
        resetRideOptions,
        findRideOptions,
        apiError,
        isLoading,
    };

    return <CategoriesContextProvider.Provider value={contextValue}>{children}</CategoriesContextProvider.Provider>;
};

export {CategoriesProvider, CategoriesContextProvider};
