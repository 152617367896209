import {FC} from "react";

import {Tooltip} from "@bolteu/kalep-react";
import {Alert as AlertIcon, Check, InfoCircleOutlined} from "@bolteu/kalep-react-icons";

interface ScoreSectionProps {
    label: string;
    unit?: string;
    current: number;
    required: number;
    tooltipContent?: string;
}

const ScoreSection: FC<ScoreSectionProps> = ({label, unit, current, required, tooltipContent}) => {
    const isWarning = current >= required ? "" : "text-danger-primary";

    return (
        <div className="flex flex-col">
            <p className="text-secondary text-sm">
                <div className="flex flex-row">
                    <span>{`${label} ${unit ? `(${unit})` : ""}`}</span>
                    {tooltipContent && (
                        <Tooltip content={tooltipContent} placement="right">
                            <div className="ml-2">
                                <InfoCircleOutlined width={20} height={20} />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </p>
            <p>
                <span className={`font-semibold ${isWarning}`}>{current}</span>
                <span className="text-secondary">/{required}</span>
                {current >= required ? (
                    <Check size="sm" className="text-action-secondary ml-2" />
                ) : (
                    <AlertIcon size="sm" className="text-danger-primary ml-2" />
                )}
            </p>
        </div>
    );
};

export default ScoreSection;
