import {IntlShape} from "react-intl";

import {Dash} from "@fleet/common/constants/dash";

interface FormatNumberOptions {
    minFractionDigits?: number;
    maxFractionDigits?: number;
    emptyValue?: string;
    zeroValue?: string;
}

export function formatNumber(intl: IntlShape, value?: number | null, options?: FormatNumberOptions): string {
    if (value === 0) {
        return options?.zeroValue ?? Dash.EM;
    }
    if (value === null || value === undefined) {
        return options?.emptyValue ?? Dash.EM;
    }

    const maxFractionDigits = options?.maxFractionDigits ?? 2;
    const minFractionDigits = options?.minFractionDigits ?? 1;
    return intl.formatNumber(value, {
        maximumFractionDigits: maxFractionDigits,
        minimumFractionDigits: minFractionDigits,
    });
}
