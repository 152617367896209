import React, {FC, useCallback, useContext} from "react";

import DisplayField from "common/components/displayField/DisplayField";
import {CountryCode} from "common/geo/constants/countries";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Tooltip, useSnackbar} from "@bolteu/kalep-react";
import {Copy, InfoCircleOutlined} from "@bolteu/kalep-react-icons";

import {TaxIntegration} from "../tin/TaxIntegration";
import TinInformation from "../tin/TinInformation";

interface TaxInfoTabProps {
    companyData: FleetOwnerPortalService.GetCompanyDetailsResponse | null;
    refreshData: () => void;
    isShowTin: boolean;
}

const TaxFiscalizationField: FC<{token: string}> = ({token}) => {
    const {i18n} = useI18n();
    const snackbar = useSnackbar();

    const copyToClipboard = useCallback(
        (text: string) => () => {
            navigator.clipboard.writeText(text);
            snackbar.add({description: i18n("common.copied_to_clipboard")}, {timeout: 2000});
        },
        [snackbar, i18n],
    );

    return (
        <div className="min-h-20 flex flex-row justify-between border-b border-neutral-300 py-4">
            <div className="flex-col">
                <h4 className="text-neutral-700">
                    {i18n("auth.app.fleet.profile.fiscalization_token.title")}
                    <Tooltip content={i18n("auth.app.fleet.profile.fiscalization_token.description")} placement="top">
                        <div className="inline-flex pl-1">
                            <InfoCircleOutlined size="xs" />
                        </div>
                    </Tooltip>
                </h4>
                <p>{token}</p>
            </div>
            <Tooltip content={i18n("copy-to-clipboard-button.copy")} placement="top">
                <IconButton
                    aria-label="Action button"
                    icon={<Copy />}
                    shape="round"
                    size="sm"
                    type="button"
                    variant="filled"
                    onClick={copyToClipboard(token)}
                />
            </Tooltip>
        </div>
    );
};

const TaxInfoTab: React.FC<TaxInfoTabProps> = ({companyData, refreshData, isShowTin}) => {
    const fleet = useContext(AccountContextProvider)?.getFleet();

    const {i18n} = useI18n();

    if (!companyData) {
        return null;
    }

    const vatCodeTitle = i18n("auth.app.company-settings.tax_info.vat_code");

    const isShowTaxIntegration = fleet?.country === CountryCode.Portugal;
    const isShowFiscalizationToken = !!fleet?.fiscalization_token;

    return (
        <div className="sm:w-8/12 sm:max-w-xs">
            <div className="mb-6">
                <DisplayField title={vatCodeTitle} value={companyData.vat_code} />
            </div>
            {isShowFiscalizationToken && (
                <div className="mb-6">
                    <TaxFiscalizationField token={fleet.fiscalization_token || ""} />
                </div>
            )}
            {isShowTin && (
                <div className="mb-6">
                    <TinInformation tinsResponse={companyData.tax_identification_numbers} refreshData={refreshData} />
                </div>
            )}
            {isShowTaxIntegration && <TaxIntegration />}
        </div>
    );
};

export default TaxInfoTab;
