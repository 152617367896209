import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod} from "common/services/period";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Skeleton} from "@bolteu/kalep-react";
import {Receipt} from "@bolteu/kalep-react-icons";

import {BalanceEarnings, BalanceExpenses} from "../../types";
import {formatPeriodWithYear} from "../../utils/formatPeriod";
import TransactionsListRow from "./TransactionsListRow";
import Balance = FleetOwnerPortalService.Balance;

const getSkeletonRow = (key: string) => (
    <div key={key} className="flex justify-between p-3">
        <Skeleton variant="rounded" width={160} height={24} />
        <Skeleton variant="rounded" width={86} height={24} />
    </div>
);

interface TransactionsListProps {
    period: ApiPeriod;
    data: Balance | null;
    isLoading: boolean;
}

const TransactionsList = ({period, data, isLoading}: TransactionsListProps) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const Wrapper = ({children}: {children: React.ReactNode}) => (
        <div className="flex flex-col gap-2">
            <span className="text-secondary pb-2 font-semibold">{formatPeriodWithYear(intl, period)}</span>
            {children}
        </div>
    );

    if (isLoading || !data) {
        return (
            <Wrapper>
                <div className="divide-y divide-neutral-200">
                    {getSkeletonRow("skeleton-row-1")}
                    {getSkeletonRow("skeleton-row-2")}
                    {getSkeletonRow("skeleton-row-3")}
                </div>
            </Wrapper>
        );
    }

    const hasTransactions = Object.values(data.earnings).some(Boolean) || Object.values(data.expenses).some(Boolean);
    if (!hasTransactions) {
        const noTransactionsTitle = i18n("auth.app.fleet.balance.transactions.list.no_transactions.title");
        const noTransactionsDescription = i18n("auth.app.fleet.balance.transactions.list.no_transactions.description");

        return (
            <Wrapper>
                <div className="flex flex-col items-center justify-center gap-4 py-16">
                    <Receipt className="text-tertiary" width={64} height={64} />
                    <div className="flex flex-col gap-2 text-center">
                        <div className="text-primary text-xl font-semibold">{noTransactionsTitle}</div>
                        <div className="text-secondary">{noTransactionsDescription}</div>
                    </div>
                </div>
            </Wrapper>
        );
    }

    const weeklyPayouts = (
        <>
            {data.expenses.payouts && (
                <TransactionsListRow
                    name={i18n("auth.app.fleet.balance.transactions_list.expenses.payouts.title")}
                    value={data.expenses.payouts}
                    currencySymbol={data.currency}
                />
            )}
        </>
    );

    const commissions = (
        <>
            {data.earnings.earning_in_app && (
                <TransactionsListRow
                    name={i18n("auth.app.fleet.balance.transactions_list.earnings.earning_in_app.title")}
                    value={data.earnings.earning_in_app}
                    currencySymbol={data.currency}
                />
            )}
            {data.expenses.commissions_in_app && (
                <TransactionsListRow
                    name={i18n("auth.app.fleet.balance.transactions_list.expenses.commissions_in_app.title")}
                    value={-data.expenses.commissions_in_app}
                    currencySymbol={data.currency}
                />
            )}
            {data.expenses.commissions_cash && (
                <TransactionsListRow
                    name={i18n("auth.app.fleet.balance.transactions_list.expenses.commissions_cash.title")}
                    value={-data.expenses.commissions_cash}
                    currencySymbol={data.currency}
                />
            )}
        </>
    );

    const keysToRemove = ["earning_in_app", "payouts", "commissions_in_app", "commissions_cash"];

    const filteredEarnings = Object.entries(data.earnings).filter(([key, _]) => !keysToRemove.includes(key));
    const filteredExpenses = Object.entries(data.expenses).filter(([key, _]) => !keysToRemove.includes(key));
    const sortedEarnings = filteredEarnings.sort((a, b) => b[1] - a[1]);
    const sortedExpenses = filteredExpenses.sort((a, b) => a[1] - b[1]);

    return (
        <Wrapper>
            <div className="divide-y divide-neutral-200">
                <TransactionsListRow
                    name={i18n("auth.app.fleet.balance.transactions_list.starting_balance.title")}
                    value={data.starting_balance}
                    currencySymbol={data.currency}
                />
                {weeklyPayouts}
                {commissions}
                {sortedEarnings.map(([key, value]) => (
                    <TransactionsListRow
                        key={key}
                        name={i18n(
                            `auth.app.fleet.balance.transactions_list.earnings.${key as keyof BalanceEarnings}.title`,
                        )}
                        value={value as number}
                        currencySymbol={data.currency}
                    />
                ))}
                {sortedExpenses.map(([key, value]) => (
                    <TransactionsListRow
                        key={key}
                        name={i18n(
                            `auth.app.fleet.balance.transactions_list.expenses.${key as keyof BalanceExpenses}.title`,
                        )}
                        value={-(value as number)}
                        currencySymbol={data.currency}
                    />
                ))}
            </div>
            <div className="h-4 w-full border-b border-neutral-500" />
            <TransactionsListRow
                name={i18n("auth.app.fleet.balance.transactions_list.ending_balance.title")}
                value={data.ending_balance}
                currencySymbol={data.currency}
            />
        </Wrapper>
    );
};

export default TransactionsList;
