import {useContext, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {format} from "date-fns";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";
import {formatNumber} from "@fleet/common/utils/numberFormatUtils";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {TABLE_ROWS_PER_PAGE} from "../../contants";

import GetCompensationsRequest = FleetOwnerReportingService.GetCompensationsRequest;

export interface CompensationInvoiceData {
    id: number;
    date: string;
    name: string;
    sum: string;
    currency: string;
}

const fetchCompensations = (api: Api, body: GetCompensationsRequest) => api.fleetOwnerReporting.getCompensations(body);

export const useCompensations = (offset: number) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {data, fetch, status} = useFetch(fetchCompensations);

    useEffect(() => {
        if (!fetch || !fleet) {
            return;
        }
        const body: GetCompensationsRequest = {
            limit: TABLE_ROWS_PER_PAGE,
            offset,
        };
        fetch(body);
    }, [fetch, fleet, offset]);

    const invoices = useMemo<CompensationInvoiceData[]>(() => {
        if (status !== FetchStatus.Success) {
            return [];
        }
        const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
        return data.list.map((d) => {
            const invoiceDate = new Date(d.date * 1000);

            return {
                id: d.id,
                name: d.description ?? i18n("auth.app.invoices.compensations_v2.compensation"),
                date: format(invoiceDate, "P", {locale: dateFnsLocale}),
                sum: formatNumber(intl, d.sum_with_vat, {minFractionDigits: 2, maxFractionDigits: 2}),
                currency: d.currency,
            };
        });
    }, [data, i18n, intl, status]);

    return {invoices, status};
};
