import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import DriverFilter from "common/components/DriverFilter";
import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {PaymentMethod} from "common/constants/PaymentMethod";
import {useDrivers} from "common/hooks/useDrivers";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {Custom, Period} from "common/services/period";
import {endOfMonth, setDate} from "date-fns";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {getYearMonthDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, SelectOption} from "@bolteu/kalep-react";

import LoadMoreTable from "../components/LoadMoreTable";
import {TABLE_ROWS_PER_PAGE} from "../contants";
import GeneratedFiles from "./components/GeneratedFiles";
import PaymentFilter from "./components/PaymentFilter";
import PeriodFilter from "./components/PeriodFilter";
import {useInvoices} from "./hooks/useInvoices";
import {useTableColumns} from "./hooks/useTableColumns";

const fetchGenerateReport = (api: Api, body: FleetOwnerReportingService.GenerateCompanyReportRequest) =>
    api.fleetOwnerReporting.generateReportRequest(body);

const fetchCompanyReports = (api: Api) => api.fleetOwnerReporting.getCompanyReports();

const FleetToRiderInvoices = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const [selectedPeriodDate, setSelectedPeriodDate] = useState(setDate(new Date(), 1));
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
    const [selectedDriverId, setSelectedDriverId] = useState<number | null>(null);
    const [dataOffset, setDataOffset] = useState<number>(0);
    const {invoices, status: getInvoicesStatus} = useInvoices({
        date: selectedPeriodDate,
        paymentMethod: selectedPaymentMethod,
        driverId: selectedDriverId,
        offset: dataOffset,
    });

    const dateRange = useMemo<Custom>(
        () => ({
            startDate: selectedPeriodDate,
            endDate: endOfMonth(selectedPeriodDate),
            period: Period.CUSTOM,
        }),
        [selectedPeriodDate],
    );

    const {drivers, status} = useDrivers(dateRange);
    const columns = useTableColumns();
    const {trackEvent} = useTracking();

    const {
        fetch: generateReport,
        status: generateReportStatus,
        error: generateReportError,
    } = useFetch(fetchGenerateReport);
    const {fetch: fetchGeneratedReports, data: generatedReports} = useFetch(fetchCompanyReports);

    const getGeneratedReports = useCallback(() => {
        if (!fetchGeneratedReports || !fleet) {
            return;
        }
        fetchGeneratedReports({});
    }, [fetchGeneratedReports, fleet]);

    useEffect(() => {
        getGeneratedReports();
    }, [getGeneratedReports]);

    useEffect(() => {
        if (FetchStatus.Success === generateReportStatus) {
            trackEvent(EventName.FleetToRiderFileGenerationQueued);
            getGeneratedReports();
        }
    }, [generateReportStatus, getGeneratedReports, trackEvent]);

    useEffect(() => {
        if (FetchStatus.Error === generateReportStatus && generateReportError.message !== "") {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.GenerateReport,
                errorMessage: generateReportError.message,
            });
        }
    }, [generateReportError, generateReportStatus, trackEvent]);

    const handlePeriodChange = useCallback((date: Date) => {
        setDataOffset(0);
        setSelectedPeriodDate(date);
    }, []);

    const handlePaymentMethodChange = useCallback((paymentMethod: PaymentMethod | null) => {
        setDataOffset(0);
        setSelectedPaymentMethod(paymentMethod);
    }, []);

    const handleDriverIdChange = useCallback((selectedDrivers: SelectOption[] | null) => {
        setDataOffset(0);
        const driver = selectedDrivers ? selectedDrivers[0] : null;
        const driverId = driver ? (driver.value as number) : null;
        setSelectedDriverId(driverId === -1 ? null : driverId);
    }, []);

    const handleGenerateClick = useCallback(() => {
        if (!generateReport || !fleet) {
            return;
        }
        const body: FleetOwnerReportingService.GenerateCompanyReportRequest = {
            month: getYearMonthDate(selectedPeriodDate),
        };
        if (selectedPaymentMethod) {
            body.payment_method = selectedPaymentMethod;
        }
        if (selectedDriverId) {
            body.driver_id = selectedDriverId;
        }
        generateReport(body);
    }, [fleet, generateReport, selectedDriverId, selectedPaymentMethod, selectedPeriodDate]);

    const handleLoadMoreClick = useCallback(() => {
        setDataOffset(dataOffset + TABLE_ROWS_PER_PAGE);
        trackEvent(EventName.FleetToRiderLoadMoreClicked);
    }, [dataOffset, trackEvent]);

    const hasNoInvoices = !invoices.length && dataOffset === 0;

    return (
        <div className="pt-2">
            <div className="mb-8 flex flex-col gap-y-2 gap-x-4 xl:flex-row">
                <div className="flex flex-col gap-y-2 gap-x-4 sm:flex-row">
                    <PeriodFilter selectedDate={selectedPeriodDate} onChange={handlePeriodChange} />
                    <PaymentFilter value={selectedPaymentMethod} onChange={handlePaymentMethodChange} />
                </div>
                <div className="flex w-full flex-col gap-y-6 gap-x-4 sm:flex-row">
                    <DriverFilter
                        driverIds={selectedDriverId !== null ? [selectedDriverId] : null}
                        onChange={handleDriverIdChange}
                        drivers={drivers}
                        label={i18n("common.drivers_capitalized")}
                        loading={status === FetchStatus.Loading}
                    />
                    <div className="sm:flex sm:w-full sm:items-end sm:justify-end">
                        <Button
                            variant="secondary"
                            disabled={hasNoInvoices}
                            onClick={handleGenerateClick}
                            loading={generateReportStatus === FetchStatus.Loading}
                        >
                            {i18n("auth.app.invoices.fleet-to-rider.generate_report")}
                        </Button>
                    </div>
                </div>
            </div>
            <LoadMoreTable
                items={invoices}
                columns={columns}
                aria-label="Fleet to rider invoices"
                hasFilters
                loadMore={handleLoadMoreClick}
                dataFetchStatus={getInvoicesStatus}
            />
            <GeneratedFiles
                drivers={drivers}
                reports={generatedReports?.list}
                getGeneratedReports={getGeneratedReports}
            />
        </div>
    );
};
export default FleetToRiderInvoices;
