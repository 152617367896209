import React from "react";

import {CustomerSupportContact} from "common/components/customerSupport/CustomerSupportContact";
import DisplayField from "common/components/displayField/DisplayField";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface BankingDetailsTabProps {
    companyData: FleetOwnerPortalService.GetCompanyDetailsResponse | null;
}

const BankingDetailsTab: React.FC<BankingDetailsTabProps> = ({companyData}) => {
    const {i18n} = useI18n();

    if (!companyData) {
        return null;
    }

    const recipientTitle = i18n("auth.app.company-settings.banking_details.recipient");
    const ibanTitle = i18n("auth.app.company-settings.banking_details.iban");
    const swiftTitle = i18n("auth.app.company-settings.banking_details.swift");
    const bankTitle = i18n("auth.app.company-settings.banking_details.bank");

    const bankValue =
        [companyData.account_bank_name, companyData.account_bank_address].filter(Boolean).join(" / ") || null;

    return (
        <div className="md:w-5/12">
            <div className="mb-4">
                <DisplayField title={recipientTitle} value={companyData.account_recipient} />
                <DisplayField title={ibanTitle} value={companyData.account_iban} />
                <DisplayField title={swiftTitle} value={companyData.account_swift} />
                <DisplayField title={bankTitle} value={bankValue} />
            </div>
            <CustomerSupportContact
                customerSupportEmail={companyData.customer_support_email}
                csWidgetKey="auth.app.company-settings.contact_support_block.support_widget_enabled"
                emailKey="auth.app.company-settings.contact_support_block.customer_support_email_provided"
                emailLinkPlaceholder="email"
                fallbackKey="auth.app.company-settings.contact_support_block.no_customer_support_email"
            />
        </div>
    );
};

export default BankingDetailsTab;
