import {useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {RadioGroup, Typography} from "@bolteu/kalep-react";

import {DEFAULT_CATEGORY_ICON_URL} from "../../../constants";
import {CustomRadio} from "../../CustomRadio";
import {CategorySkeleton} from "./CategorySkeleton";
import {RideInfo} from "./RideInfo";

interface Props {
    selectedCategoryId?: number;
    onChange: (category: {id?: number; price?: string; order_duration_in_seconds?: number}) => void;
    categories?: FleetPortalOrderService.Category[];
    isLoading: boolean;
    categoryError?: string;
    isManualPriceEnabledAndEntered: boolean;
}

export const Categories = ({
    selectedCategoryId,
    onChange,
    categories,
    isLoading,
    categoryError,
    isManualPriceEnabledAndEntered,
}: Props) => {
    const {i18n} = useI18n();

    const onCategoryChange = useCallback(
        (event) => {
            const categoryId = Number(event.target.value);
            const newCategory = categories?.find((category) => category.id === categoryId);
            onChange({
                id: newCategory ? categoryId : undefined,
                price: newCategory ? newCategory.price.price_str : undefined,
                order_duration_in_seconds: newCategory ? newCategory.order_duration_seconds : undefined,
            });
        },
        [categories, onChange],
    );

    const selectedCategory =
        categories?.find((category) => category.id === selectedCategoryId) ?? (categories ?? [])[0];

    return (
        <>
            <Typography fontWeight="semibold" fontSize="text-sm">
                {i18n("auth.app.orders.scheduled_rides.create-dialog.ride-category")}
            </Typography>
            {selectedCategory && <RideInfo selectedCategory={selectedCategory} isLoading={isLoading} />}
            <div>
                <RadioGroup
                    name="category"
                    value={String(selectedCategoryId)}
                    onChange={onCategoryChange}
                    error={Boolean(categoryError)}
                >
                    <div className="flex w-full flex-col gap-6">
                        {isLoading && (
                            <>
                                <CategorySkeleton />
                                <CategorySkeleton />
                            </>
                        )}
                        {!isLoading &&
                            (categories ?? []).map((category) => (
                                <div key={category.id} className="flex w-full justify-between">
                                    <CustomRadio
                                        label={category.name}
                                        value={String(category.id)}
                                        iconUrl={category.category_icon_url}
                                        defaultIconUrl={DEFAULT_CATEGORY_ICON_URL}
                                    />
                                    <Typography
                                        fontWeight="semibold"
                                        color={isManualPriceEnabledAndEntered ? "tertiary" : "primary"}
                                        inlineStyle={
                                            isManualPriceEnabledAndEntered ? {textDecorationLine: "line-through"} : {}
                                        }
                                    >
                                        {category.price.price_str}
                                    </Typography>
                                </div>
                            ))}
                    </div>
                </RadioGroup>
                {categoryError && (
                    <Typography color="danger-primary" paddingTop={2}>
                        {categoryError}
                    </Typography>
                )}
            </div>
        </>
    );
};
