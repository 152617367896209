import {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {useIntl} from "react-intl";

import {DraggableList} from "common/components/dragAndDrop/DraggableList";
import {DragPayload, DropTarget} from "common/components/dragAndDrop/types";
import {useI18n} from "common/hooks/useI18n";
import {getCurrentDate} from "common/util/dateUtil";
import {addDays} from "date-fns";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";
import {v4} from "uuid";
import {TextButton} from "@fleet/common/components/TextButton";
import {getDateFormat} from "@fleet/common/utils/dateFormatUtils";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {DatePicker, IconButton, Typography} from "@bolteu/kalep-react";
import {ReorderVert} from "@bolteu/kalep-react-icons";

import {CategoriesContextProvider} from "../../../CategoriesContextProvider";
import {useAddressSuggestions} from "../../../hooks/useAddressSuggestions";
import {Address, AddressType, CreateDrawerType, Step1Data, Step2Data} from "../../../types";
import {RouteDetailsMap} from "../map";
import {Stop} from "../Stop";

const MAX_DROPOFFS = 3;

interface Props {
    data: Step1Data & Step2Data;
    onChange: (data: Partial<Step1Data>) => void;
    validationErrors?: FleetPortalOrderService.ValidationError[];
    drawerType: CreateDrawerType;
    geoLocation: GeolocationPosition | null;
}

export const RouteDetails = ({drawerType, data, onChange, validationErrors, geoLocation}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const findRideOptionsFetched = useRef<boolean>(false);
    const {findRideOptions, rideOptions, apiError: findRideOptionsError} = useContext(CategoriesContextProvider);
    const schedulingLimits = useContext(ScheduledRidesContextProvider).getSchedulingLimits();
    const {getSuggestions: getPickupSuggestions, fetchData: pickupFetchData} = useAddressSuggestions(
        AddressType.Pickup,
    );
    const {getSuggestions: getDropoffSuggestions, fetchData: dropoffFetchData} = useAddressSuggestions(
        AddressType.Dropoff,
    );

    useEffect(() => {
        // To prevent extra call when coming back from next step
        const notFetchedInCurrentComponentLifecycle = !findRideOptionsFetched.current && rideOptions;
        if (notFetchedInCurrentComponentLifecycle) {
            findRideOptionsFetched.current = true;
            return;
        }
        if (!data.pickup.lat || !data.pickup.lng || !data.dropoffs.some((dropoff) => !!dropoff.lat)) {
            return;
        }
        const pickup = {...data.pickup, lat: data.pickup.lat, lng: data.pickup.lng};
        findRideOptions({...data, pickup}, drawerType);
        findRideOptionsFetched.current = true;
    }, [data, drawerType, findRideOptions, rideOptions]);

    useEffect(() => {
        // Suggestions have popular places for empty search, on first render get them
        getPickupSuggestions("");
        getDropoffSuggestions("");
    }, [getDropoffSuggestions, getPickupSuggestions]);

    const onDateTimeChange = useCallback(
        (newDate: Date | Date[] | null) => {
            if (newDate && !Array.isArray(newDate)) {
                onChange({scheduled_for: newDate});
            }
        },
        [onChange],
    );

    const onPickupChange = useCallback(
        (newAddress: Address) => {
            onChange({pickup: newAddress});
        },
        [onChange],
    );

    const onDropoffChange = useCallback(
        (newAddress: Address, deleteAddress?: boolean) => {
            if (deleteAddress) {
                const newDropoffs = data.dropoffs.filter((address) => address.key !== newAddress.key);
                onChange({dropoffs: newDropoffs});
                return;
            }
            const indexToReplace = data.dropoffs.findIndex((address) => address.key === newAddress?.key);
            const newDropoffs = [...data.dropoffs];
            newDropoffs.splice(indexToReplace, 1, newAddress);
            onChange({dropoffs: newDropoffs});
        },
        [data.dropoffs, onChange],
    );

    const onAddDestinationClick = useCallback(() => {
        const dropoffs = [...data.dropoffs];
        const lastDropoff = dropoffs.splice(dropoffs.length - 1, 1);
        onChange({
            dropoffs: [
                ...dropoffs,
                {address_name: "", address_extra: "", full_address: "", key: v4(), place_id: null},
                ...lastDropoff,
            ],
        });
    }, [data.dropoffs, onChange]);

    const onReorderClick = useCallback(() => {
        onChange({dropoffs: [data.pickup], pickup: data.dropoffs[0]});
    }, [data.dropoffs, data.pickup, onChange]);

    const draggableAddresses = useMemo(() => [data.pickup, ...data.dropoffs], [data.dropoffs, data.pickup]);

    const isDraggedItemSame = useCallback((item: Address, draggedItem: DragPayload<Address>) => {
        return item.key === draggedItem.data.key;
    }, []);

    const isDragTargetItemSame = useCallback((item: Address, targetItem: DropTarget<Address>) => {
        return item.key === targetItem.data.key;
    }, []);

    const onDragItemsChange = useCallback(
        (items: Address[]) => {
            onChange({pickup: items[0], dropoffs: items.slice(1)});
        },
        [onChange],
    );

    const allValidationErrors = [...(validationErrors ?? []), ...(findRideOptionsError ? [findRideOptionsError] : [])];
    const findRideOptionsGeneralError =
        findRideOptionsError?.property === "general" ? findRideOptionsError.error : undefined;
    const scheduledAtError = allValidationErrors?.find((error) => error.property === "scheduled_for")?.error;
    const pickupError = allValidationErrors?.find((error) => error.property === "pickup")?.error;
    const hasDropoffChildrenErrors = allValidationErrors?.some(
        (error) => error.property === "dropoffs" && error.children,
    );
    const generalDropoffError = allValidationErrors?.find(
        (error) => error.property === "dropoffs" && !error.children,
    )?.error;
    const isScheduledOrder = drawerType === CreateDrawerType.SCHEDULE;
    const reorderButtonMargin = hasDropoffChildrenErrors || generalDropoffError ? "" : "mt-3";
    const hasMultipleStops = data.dropoffs.length > 1;

    const selectedCategory = rideOptions?.categories.find((category) => category.id === data.category_id);
    const routePolyline = selectedCategory ? selectedCategory.polyline : (rideOptions?.categories[0] ?? {}).polyline;

    return (
        <div>
            {isScheduledOrder && (
                <DatePicker
                    label={i18n("common.date")}
                    value={data.scheduled_for}
                    onChange={onDateTimeChange}
                    hasTimeSelect
                    format={`${getDateFormat(intl)} HH:mm`}
                    minDate={getCurrentDate()}
                    maxDate={addDays(getCurrentDate(), schedulingLimits.maximum_date_in_future_days)}
                    locale={intl.locale}
                    fullWidth
                    isError={Boolean(scheduledAtError)}
                    helperText={scheduledAtError}
                />
            )}
            <div className="mt-10 flex items-center">
                <DraggableList
                    list={draggableAddresses}
                    className="flex w-full flex-col"
                    isDraggedItemSame={isDraggedItemSame}
                    isTargetItemSame={isDragTargetItemSame}
                    onChange={onDragItemsChange}
                >
                    <Stop
                        isPickup
                        address={data.pickup}
                        onChange={onPickupChange}
                        validationError={pickupError}
                        geoLocation={geoLocation}
                        hasMultipleStops={hasMultipleStops}
                        defaultSuggestions={pickupFetchData.data}
                    />
                    {data.dropoffs.map((address, index, dropoffs) => {
                        const isLastDropoff = index === dropoffs.length - 1;
                        const validationError = allValidationErrors?.find((error) => error.property === "dropoffs");
                        let stopError = validationError?.children?.find(
                            (error) => error.property === address.key,
                        )?.error;
                        if (!stopError && isLastDropoff && generalDropoffError) {
                            stopError = generalDropoffError;
                        }
                        return (
                            <Stop
                                key={address.key}
                                address={address}
                                isLastAddressField={isLastDropoff}
                                hasMultipleStops={hasMultipleStops}
                                onChange={onDropoffChange}
                                validationError={stopError}
                                geoLocation={geoLocation}
                                defaultSuggestions={dropoffFetchData.data}
                            />
                        );
                    })}
                </DraggableList>
                {!hasMultipleStops && (
                    <div>
                        <IconButton
                            onClick={onReorderClick}
                            icon={<ReorderVert />}
                            aria-label="Change pickup and dropoff"
                            variant="ghost"
                            size="lg"
                            overrideClassName={reorderButtonMargin}
                        />
                    </div>
                )}
            </div>
            {data.dropoffs.length < MAX_DROPOFFS && (
                <div className="mt-4">
                    <TextButton
                        onClick={onAddDestinationClick}
                        text={i18n("auth.app.orders.scheduled_rides.create-dialog.add-destination")}
                    />
                </div>
            )}
            {!!findRideOptionsGeneralError && (
                <div className="mt-2">
                    <Typography color="danger-primary">{findRideOptionsGeneralError}</Typography>
                </div>
            )}
            <RouteDetailsMap
                pickup={data.pickup}
                dropoffs={data.dropoffs}
                geoLocation={geoLocation}
                routePolyline={routePolyline}
            />
        </div>
    );
};
