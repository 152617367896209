import {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {generatePath} from "react-router-dom";

import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {format} from "date-fns";
import {Dash} from "@fleet/common/constants/dash";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {GhostButton, IconButton, Tooltip} from "@bolteu/kalep-react";
import {Check, Cross} from "@bolteu/kalep-react-icons";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {ManualAcceptedOrdersTableData} from "../components/ManualAcceptIncomingOrdersTable";

export function useTableColumns() {
    const intl = useIntl();
    const {i18n} = useI18n();

    const goToDriverProfile = useCallback(
        (id: string) => () => {
            window.open(generatePath(FleetRoute.DRIVER, {id}));
        },
        [],
    );

    return useMemo<TableHeaders<ManualAcceptedOrdersTableData>>(() => {
        return {
            accepted_time: {
                label: i18n("auth.app.orders.incoming.time"),
                renderCell: (item: ManualAcceptedOrdersTableData) => {
                    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
                    const date = format(item.incoming_time * 1000, "P", {locale: dateFnsLocale});
                    const time = format(item.incoming_time * 1000, "HH:mm", {locale: dateFnsLocale});

                    return (
                        <DefaultCellContent compact>
                            <div className="flex h-full w-full flex-col justify-center">
                                <div className="text-primary antialiased">{date}</div>
                                <div className="text-secondary whitespace-nowrap text-xs antialiased">{time}</div>
                            </div>
                        </DefaultCellContent>
                    );
                },
            },
            driver_name: {
                label: i18n("auth.app.orders.incoming.driver"),
                renderCell: (item: ManualAcceptedOrdersTableData) => (
                    <DefaultCellContent>
                        <GhostButton
                            onClick={goToDriverProfile(String(item.driver_id))}
                            overrideClassName="hover:underline"
                        >
                            {item.driver_name}
                        </GhostButton>
                    </DefaultCellContent>
                ),
            },
            client_name: {
                label: i18n("auth.app.orders.incoming.rider"),
                renderCell: (item: ManualAcceptedOrdersTableData) => (
                    <DefaultCellContent>{item.client_name}</DefaultCellContent>
                ),
            },
            pickup_address: {
                label: i18n("auth.app.orders.incoming.from"),
                renderCell: (item: ManualAcceptedOrdersTableData) => (
                    <DefaultCellContent className={item.address === Dash.EM ? "text-content-tertiary" : ""}>
                        {item.address}
                    </DefaultCellContent>
                ),
            },
        };
    }, [i18n, intl.locale, goToDriverProfile]);
}

export function useTableActions(
    onApprove: (orderTryId: number) => () => void,
    onDecline: (orderTryId: number) => () => void,
) {
    const {i18n} = useI18n();
    return useMemo(() => {
        return [
            {
                label: i18n("auth.app.orders.incoming.accept"),
                customInlineRenderer: ({row}: {row: ManualAcceptedOrdersTableData}) => (
                    <div className="mr-4">
                        <Tooltip content={i18n("auth.app.orders.incoming.accept")} placement="top" delay={600}>
                            <IconButton
                                color="action"
                                icon={<Check size="lg" />}
                                aria-label="Approve"
                                onClick={onApprove(row.id)}
                                size="md"
                                shape="square"
                                overrideClassName="text-green-700 bg-green-100 w-16 rounded"
                            />
                        </Tooltip>
                    </div>
                ),
            },
            {
                label: i18n("auth.app.orders.incoming.reject"),
                customInlineRenderer: ({row}: {row: ManualAcceptedOrdersTableData}) => (
                    <div>
                        <Tooltip content={i18n("auth.app.orders.incoming.reject")} placement="top" delay={600}>
                            <IconButton
                                color="danger"
                                icon={<Cross size="lg" />}
                                aria-label="Reject"
                                onClick={onDecline(row.id)}
                                size="md"
                                shape="square"
                                overrideClassName="text-red-700 bg-red-100 w-16 rounded"
                            />
                        </Tooltip>
                    </div>
                ),
            },
        ];
    }, [onApprove, onDecline, i18n]);
}
