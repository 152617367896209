import {useI18n} from "common/hooks/useI18n";
import {Dash} from "@fleet/common/constants/dash";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {Typography} from "@bolteu/kalep-react";

import {AlignHorizontal} from "../types";

export enum RenderingUnit {
    MINUTES = "minutes",
    HOURS = "hours",
    DAYS = "days",
    ALL = "all",
}

interface Props {
    value: number;
    isSubCell?: boolean;
    largestUnit?: RenderingUnit;
    alignHorizontal?: AlignHorizontal;
}

export const DurationCell = ({
    value,
    isSubCell,
    alignHorizontal,
    largestUnit = RenderingUnit.HOURS,
}: Props): React.ReactElement => {
    if (!value) {
        return (
            <Typography variant="body-tabular-s-regular" color="tertiary" fontSize={isSubCell ? "text-xs" : "text-sm"}>
                {Dash.EN}
            </Typography>
        );
    }

    if (largestUnit !== RenderingUnit.ALL) {
        switch (largestUnit) {
            case RenderingUnit.DAYS: {
                const days = Math.floor(value / (3600 * 24));
                const remainingSeconds = value % (3600 * 24);
                const hours = Math.floor(remainingSeconds / 3600);

                return (
                    <DurationRenderer
                        days={days}
                        hours={hours}
                        alignHorizontal={alignHorizontal}
                        isSubCell={isSubCell}
                    />
                );
            }

            case RenderingUnit.HOURS: {
                const hours = Math.floor(value / 3600);
                const remainingSeconds = value % 3600;
                const minutes = Math.floor(remainingSeconds / 60);

                return (
                    <DurationRenderer
                        hours={hours}
                        minutes={minutes}
                        alignHorizontal={alignHorizontal}
                        isSubCell={isSubCell}
                    />
                );
            }

            case RenderingUnit.MINUTES: {
                const minutes = Math.floor(value / 60);
                return <DurationRenderer minutes={minutes} alignHorizontal={alignHorizontal} isSubCell={isSubCell} />;
            }

            default:
                return UnreachableCode.never(largestUnit, <></>);
        }
    }

    const years = Math.floor(value / (3600 * 24 * 365));
    const remainingSeconds = value % (3600 * 24 * 365);

    const months = Math.floor(remainingSeconds / (3600 * 24 * 30));
    const remainingAfterMonths = remainingSeconds % (3600 * 24 * 30);

    const days = Math.floor(remainingAfterMonths / (3600 * 24));
    const remainingAfterDays = remainingAfterMonths % (3600 * 24);

    const hours = Math.floor(remainingAfterDays / 3600);
    const remainingAfterHours = remainingAfterDays % 3600;

    const minutes = Math.floor(remainingAfterHours / 60);

    return (
        <DurationRenderer
            years={years}
            months={months}
            days={days}
            hours={hours}
            minutes={minutes}
            alignHorizontal={alignHorizontal}
            isSubCell={isSubCell}
        />
    );
};

interface DurationRendererProps {
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    alignHorizontal?: AlignHorizontal;
    isSubCell?: boolean;
}

function DurationRenderer({years, months, days, hours, minutes, alignHorizontal, isSubCell}: DurationRendererProps) {
    const {i18n} = useI18n();

    const yearsLabel = i18n("common.years_abbreviation");
    const monthsLabel = i18n("common.months_abbreviation");
    const daysLabel = i18n("common.days_abbreviation");
    const hoursLabel = i18n("common.hour_abbreviation");
    const minutesLabel = i18n("common.minute_abbreviation");

    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    return (
        <div
            className={`flex ${
                alignHorizontal === AlignHorizontal.RIGHT ? "justify-end" : "justify-start"
            } gap-1 whitespace-nowrap`}
        >
            {years && years > 0 ? (
                <div className="flex items-baseline gap-x-1">
                    <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                        {years}
                    </Typography>
                    <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                        {yearsLabel}
                    </Typography>
                </div>
            ) : (
                <></>
            )}
            {months && months > 0 ? (
                <div className="flex items-baseline gap-x-1">
                    <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                        {months}
                    </Typography>
                    <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                        {monthsLabel}
                    </Typography>
                </div>
            ) : (
                <></>
            )}
            {days && days > 0 ? (
                <div className="flex items-baseline gap-x-1">
                    <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                        {days}
                    </Typography>
                    <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                        {daysLabel}
                    </Typography>
                </div>
            ) : (
                <></>
            )}
            {hours && hours > 0 ? (
                <div className="flex items-baseline gap-x-1">
                    <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                        {hours}
                    </Typography>
                    <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                        {hoursLabel}
                    </Typography>
                </div>
            ) : (
                <></>
            )}
            {minutes && minutes > 0 ? (
                <div className="flex items-baseline gap-x-1">
                    <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                        {minutes}
                    </Typography>
                    <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                        {minutesLabel}
                    </Typography>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
