import {IntlShape} from "react-intl";

import {formatNumber} from "./numberFormatUtils";

export function formatCurrencyValue(intl: IntlShape, value: number) {
    return formatNumber(intl, value, {minFractionDigits: 2, maxFractionDigits: 2, zeroValue: "0.00"});
}

export function formatCurrencyValueWithSymbol(intl: IntlShape, value: number, currency: string) {
    return `${formatCurrencyValue(intl, value)}${currency}`;
}
