import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {Button, Dialog} from "@bolteu/kalep-react";

export enum ConfirmActionDialogVariant {
    Primary = "primary",
    Secondary = "secondary",
    Danger = "danger",
}

export interface Props {
    isOpen: boolean;
    isLoading: boolean;
    closeDialog: () => void;
    titleText: string;
    descriptionText: string;
    cancelButtonText: string;
    confirmButtonText: string;
    confirmButtonVariant: ConfirmActionDialogVariant;
    confirmButtonAction: () => void;
}

export const ConfirmActionDialog = ({
    isOpen,
    isLoading,
    closeDialog,
    titleText,
    descriptionText,
    cancelButtonText,
    confirmButtonText,
    confirmButtonVariant,
    confirmButtonAction,
}: Props) => {
    return (
        <ResponsiveDialog title={titleText} isOpen={isOpen} onRequestClose={closeDialog}>
            <Dialog.Content>
                <div className="text-base text-neutral-700">{descriptionText}</div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={closeDialog} disabled={isLoading}>
                        {cancelButtonText}
                    </Button>
                    <Button variant={confirmButtonVariant} onClick={confirmButtonAction} loading={isLoading}>
                        {confirmButtonText}
                    </Button>
                </div>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};
