import * as React from "react";

import {Dash} from "@fleet/common/constants/dash";

import {IconButton, Tooltip} from "@bolteu/kalep-react";
import {InfoCircleOutlined as InfoIcon} from "@bolteu/kalep-react-icons";

interface Props {
    title: string;
    value: string | null;
    postfix?: string;
    subtext?: React.ReactNode;
    tooltip?: string;
}

const StatCard: React.FC<Props> = ({title, value, postfix, subtext, tooltip}) => {
    const valueTextColor = value === null ? "text-secondary" : "text-primary";
    const valueOrDash = value === null ? Dash.EN : value;

    return (
        <div className="flex h-[116px] min-w-[227px] max-w-[288px] flex-1 flex-col justify-between gap-0.5 rounded-lg border border-neutral-400 p-4">
            <div className="flex flex-col gap-1">
                <div className="flex items-center justify-between gap-2">
                    <div className="text-secondary text-sm">{title}</div>
                    {tooltip && (
                        <Tooltip content={tooltip} placement="top">
                            <IconButton
                                overrideClassName="h-[28px] w-[28px]"
                                aria-label="tooltip"
                                variant="ghost"
                                icon={<InfoIcon />}
                            />
                        </Tooltip>
                    )}
                </div>
                <div className={`flex gap-0.5 ${valueTextColor}`}>
                    <span className="text-xl font-semibold">{valueOrDash}</span>
                    <span>{postfix}</span>
                </div>
            </div>
            <div className="text-xs">{subtext}</div>
        </div>
    );
};

export default StatCard;
