import {FC} from "react";

import {CustomerSupportContact} from "common/components/customerSupport/CustomerSupportContact";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Typography} from "@bolteu/kalep-react";

interface Props {
    blocks: FleetOwnerPortalService.CompanyBlock[];
    customerSupportEmail?: string | null;
}

export const BlockAlert: FC<Props> = ({blocks, customerSupportEmail}) => {
    const {i18n} = useI18n();
    const now = new Date().getTime();
    const activeBlocks = blocks.filter((block) => block.start * 1000 < now && (!block.end || block.end * 1000 > now));

    if (!activeBlocks.length) {
        return null;
    }

    const blockDescriptions = new Set(activeBlocks.map((block) => block.description));
    const blockNames = new Set(activeBlocks.map((block) => block.name));
    const title = `${i18n("company.blocks.access_to_your_company_is_restricted")} - ${Array.from(blockNames).join(
        ", ",
    )}`;

    return (
        <Alert severity="error" title={title}>
            <ul className={blockDescriptions.size > 1 ? "ml-4 list-outside list-disc" : ""}>
                {Array.from(blockDescriptions).map((desc) => (
                    <li key={desc}>
                        <Typography variant="body-s-regular" color="secondary">
                            {desc}
                        </Typography>
                    </li>
                ))}
            </ul>
            <div className="mt-2">
                <Typography variant="body-s-regular" color="secondary">
                    {i18n("company.blocks.access_restriction_applies_to_everything")}
                </Typography>
            </div>
            <div className="mt-2">
                <CustomerSupportContact
                    customerSupportEmail={customerSupportEmail}
                    csWidgetKey="common.if_issues_contact_customer_support"
                    csWidgetLinkPlaceholder="customer_support"
                    emailKey="common.if_issues_contact_customer_support"
                    emailLinkPlaceholder="customer_support"
                    fallbackKey="common.if_issues_contact_customer_support"
                />
            </div>
        </Alert>
    );
};
