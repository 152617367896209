import {useCallback, useContext, useEffect, useRef, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {I18nFn, useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getCurrentDate} from "common/util/dateUtil";
import {add, getUnixTime} from "date-fns";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";
import {v4} from "uuid";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Drawer} from "@bolteu/kalep-react";

import {CategoriesContextProvider} from "../../CategoriesContextProvider";
import {TOTAL_STEPS} from "../../constants";
import {useCreateOrderValidations} from "../../hooks/useCreateOrderValidations";
import {CreateDrawerType, CreateOrderData, StepType} from "../../types";
import {getApiAddressFromAddress} from "../../util";
import {StepHeader} from "./StepHeader";
import {Steps} from "./Steps";

import OrderCreationType = FleetPortalOrderService.OrderCreationType;

type OrderCreationRequest =
    | FleetPortalOrderService.ScheduledRideCreationForm
    | FleetPortalOrderService.InstantOrderCreationForm;

interface Props {
    type: CreateDrawerType;
    onClose: () => void;
    onCreate: (type: CreateDrawerType) => void;
    geoLocation: GeolocationPosition | null;
}

const getDefaultData = (): CreateOrderData => ({
    create_order_uuid: "",
    // Step 1
    scheduled_for: getCurrentDate(),
    dropoffs: [{address_name: "", address_extra: "", full_address: "", key: v4(), place_id: null}],
    pickup: {address_name: "", address_extra: "", full_address: "", key: v4(), place_id: null},
    // Step 2
    payment_method: FleetPortalOrderService.CreateOrderPaymentMethod.CASH,
    is_manual_price_enabled: false,
    // Stop 3
    assignment_type: FleetPortalOrderService.AssignmentType.AUTOMATIC,
    driver_id: undefined,
    phone_area_code: "",
    phone_number: "",
    comment: undefined,
});

const getButtonTexts = (activeStep: StepType, i18n: I18nFn) => {
    switch (activeStep) {
        case 0:
            return {secondary: i18n("common.cancel"), primary: i18n("form.button.next")};
        case 1:
            return {secondary: i18n("common.back"), primary: i18n("form.button.next")};
        case 2:
            return {secondary: i18n("common.back"), primary: i18n("common.create")};
        default:
            return UnreachableCode.never(activeStep, {secondary: "", primary: ""});
    }
};

// To get compiler error when keys of the step data are not defined
const STEP_1_KEYS: Required<Record<keyof FleetPortalOrderService.RouteDetailsStep, true>> = {
    type: true,
    creation_type: true,
    scheduled_for: true,
    pickup: true,
    dropoffs: true,
};
const STEP_2_KEYS: Required<Record<keyof FleetPortalOrderService.RideDetailsStep, true>> = {
    type: true,
    payment_method: true,
    category_id: true,
    manual_price: true,
};
const STEP_3_KEYS: Required<Record<keyof FleetPortalOrderService.AssignmentDetailsStep, true>> = {
    type: true,
    driver_id: true,
    passenger_phone: true,
    comment: true,
};

const mapCreateValidationErrorsToSteps = (
    validationErrors: FleetPortalOrderService.ValidationError[],
    i18n: I18nFn,
) => {
    const validationsByStep: {[key in StepType]?: FleetPortalOrderService.ValidationError[]} = {};
    const unknownErrors: FleetPortalOrderService.ValidationError[] = [];
    validationErrors.forEach((validationError) => {
        let errorMessage = validationError.error;
        if (validationError.property === "passenger_phone") {
            errorMessage = i18n("api.error.validation.phone_number.invalid");
        } else if (validationError.error === "invalid") {
            errorMessage = i18n("api.error.validation.invalid");
        } else if (validationError.error === "required") {
            errorMessage = i18n("api.error.validation.required");
        }
        const error = {...validationError, error: errorMessage};
        if (Object.keys(STEP_1_KEYS).includes(error.property)) {
            validationsByStep[0] = validationsByStep[0] ? [...validationsByStep[0], error] : [error];
        } else if (Object.keys(STEP_2_KEYS).includes(error.property)) {
            validationsByStep[1] = validationsByStep[1] ? [...validationsByStep[1], error] : [error];
        } else if (Object.keys(STEP_3_KEYS).includes(error.property)) {
            validationsByStep[2] = validationsByStep[2] ? [...validationsByStep[2], error] : [error];
        } else {
            unknownErrors.push(error);
        }
    });
    return {validationsByStep, unknownErrors};
};

const createOrderFunction = (api: Api, body: OrderCreationRequest) => api.fleetPortalOrder.createOrder(body);

export const CreateOrderDrawer = ({type, onClose, onCreate, geoLocation}: Props) => {
    const {i18n} = useI18n();
    const schedulingConfig = useContext(ScheduledRidesContextProvider);
    const {setNotification} = useContext(NotificationContext);
    const {bugsnag} = useContext(ThirdPartyContextProvider);
    const {resetRideOptions, isLoading: isLoadingRideOptions} = useContext(CategoriesContextProvider);
    const drawerContentRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<CreateOrderData>(getDefaultData());
    const [stepValidationErrors, setStepValidationErrors] = useState<{
        [key in StepType]?: FleetPortalOrderService.ValidationError[];
    }>({});
    const [activeStep, setActiveStep] = useState<StepType>(0);
    const [isValidationLoading, setIsValidationLoading] = useState(false);
    const {validateStep} = useCreateOrderValidations(type);

    const {fetch: createOrderFetch, status: createFetchStatus, data: createResponse} = useFetch(createOrderFunction);

    useEffect(() => {
        // To set initial data from context scheduled rides config
        const defaultPaymentMethod = schedulingConfig.getOrderCreationPaymentMethods()[0];
        const currentDate = getCurrentDate();
        const extraMinuteFromSeconds = Math.round(currentDate.getSeconds() / 60);
        const newDate = add(currentDate, {
            minutes: schedulingConfig.getSchedulingLimits().minimum_time_in_future_minutes + extraMinuteFromSeconds,
        });
        setData((prev) => ({
            ...prev,
            scheduled_for: newDate,
            payment_method: defaultPaymentMethod,
            create_order_uuid: v4(),
        }));
    }, [schedulingConfig]);

    useEffect(() => {
        if (createFetchStatus === FetchStatus.Success && createResponse) {
            if (createResponse.validation_errors && createResponse.validation_errors.length > 0) {
                const errors = mapCreateValidationErrorsToSteps(createResponse.validation_errors, i18n);
                if (errors.unknownErrors.length > 0) {
                    setNotification({
                        type: NotificationType.ERROR,
                        text: i18n("api.default_error"),
                        timeout: 6000,
                    });
                    const unknownKeys = errors.unknownErrors.map((error) => error.property).join(", ");
                    bugsnag.bugsnagNotify(
                        new Error(`Unknown validation errors occurred in create order, (${unknownKeys})`),
                    );
                }
                if (errors.validationsByStep[0]?.length) {
                    setActiveStep(0);
                }
                if (errors.validationsByStep[1]?.length) {
                    setActiveStep(1);
                }
                setStepValidationErrors(errors.validationsByStep);
                return;
            }
            resetRideOptions();
            onCreate(type);
        }
    }, [bugsnag, createFetchStatus, createResponse, i18n, onCreate, resetRideOptions, setNotification, type]);

    const createOrder = useCallback(
        (formData: CreateOrderData, orderCreationType: OrderCreationType) => {
            if (!createOrderFetch || !formData.category_id) {
                return;
            }
            const pickup = getApiAddressFromAddress(formData.pickup);
            if (!pickup) {
                // Pickup validations are done before
                throw Error("Address is missing lat or lng");
            }
            const manualPrice = formData.manual_price ? Number(formData.manual_price) : null;
            const hasNoDropoffs = formData.dropoffs.every((dropoff) => !dropoff.address_name);
            const dropoffs = hasNoDropoffs
                ? undefined
                : (formData.dropoffs
                      .map((dropoff) => getApiAddressFromAddress(dropoff))
                      .filter((address) => address !== null) as FleetPortalOrderService.Address[]);
            const baseData: FleetPortalOrderService.OrderCreationFormBase = {
                type: orderCreationType,
                pickup,
                dropoffs,
                category_id: formData.category_id,
                manual_price: formData.is_manual_price_enabled ? manualPrice : null,
                payment_method: formData.payment_method,
                driver_id:
                    formData.assignment_type === FleetPortalOrderService.AssignmentType.MANUAL
                        ? formData.driver_id
                        : undefined,
                passenger_phone: formData.phone_area_code.trim() + formData.phone_number.trim(),
                comment: formData.comment?.trim(),
            };
            let body: OrderCreationRequest | undefined;
            if (orderCreationType === OrderCreationType.SCHEDULED) {
                body = {
                    ...baseData,
                    type: OrderCreationType.SCHEDULED,
                    scheduled_for: getUnixTime(formData.scheduled_for),
                    ride_duration_seconds: formData.category_ride_duration_seconds,
                };
            } else {
                body = {...baseData, type: OrderCreationType.INSTANT};
            }
            createOrderFetch(body);
        },
        [createOrderFetch],
    );

    const onClickPrimaryButton = useCallback(async () => {
        setIsValidationLoading(true);
        const validations = await validateStep({stepNumber: activeStep, data});
        setIsValidationLoading(false);
        if (validations.length > 0) {
            setStepValidationErrors((prev) => ({...prev, [activeStep]: validations}));
            return;
        }
        setStepValidationErrors({});

        const isFinalStep = activeStep === TOTAL_STEPS - 1;
        if (!isFinalStep) {
            drawerContentRef.current?.scrollIntoView();
            setActiveStep((prev) => (prev + 1) as 1 | 2);
            return;
        }
        const orderCreationType =
            type === CreateDrawerType.SCHEDULE ? OrderCreationType.SCHEDULED : OrderCreationType.INSTANT;
        createOrder(data, orderCreationType);
    }, [activeStep, createOrder, data, type, validateStep]);

    const onCloseDrawer = useCallback(() => {
        onClose();
        resetRideOptions();
    }, [onClose, resetRideOptions]);

    const onClickSecondaryButton = useCallback(() => {
        if (activeStep === 0) {
            onCloseDrawer();
        } else {
            drawerContentRef.current?.scrollIntoView();
            setActiveStep((prev) => (prev - 1) as 0 | 1);
        }
    }, [activeStep, onCloseDrawer]);

    const onChange = useCallback(
        (newData: Partial<CreateOrderData>) => {
            setData((previousData) => ({...previousData, ...newData}));
            const newValueKeys = Object.keys(newData);
            if (newData.phone_area_code || newData.phone_number) {
                newValueKeys.push("passenger_phone");
            }
            setStepValidationErrors((prev) => ({
                ...prev,
                [activeStep]:
                    prev[activeStep]?.filter(
                        (error) => error.property && !newValueKeys.includes(String(error.property)),
                    ) ?? [],
            }));
        },
        [activeStep],
    );

    const isLoading = createFetchStatus === FetchStatus.Loading;
    const btnTexts = getButtonTexts(activeStep, i18n);
    const title =
        type === CreateDrawerType.SCHEDULE
            ? i18n("auth.app.orders.scheduled_rides.create-dialog.scheduled-order-title")
            : i18n("auth.app.orders.scheduled_rides.create-dialog.instant-order-title");

    return (
        <ResponsiveDrawer title={title} isOpen onRequestClose={onCloseDrawer}>
            <Drawer.Content>
                <div ref={drawerContentRef}>
                    <StepHeader activeStep={activeStep} />
                    <Steps
                        data={data}
                        onChange={onChange}
                        validationErrors={stepValidationErrors}
                        drawerType={type}
                        activeStep={activeStep}
                        geoLocation={geoLocation}
                    />
                </div>
            </Drawer.Content>
            <Drawer.Footer>
                <Button
                    onClick={onClickPrimaryButton}
                    loading={isLoading || isValidationLoading || isLoadingRideOptions}
                >
                    {btnTexts.primary}
                </Button>
                <Button
                    variant="secondary"
                    onClick={onClickSecondaryButton}
                    disabled={isLoading || isValidationLoading}
                >
                    {btnTexts.secondary}
                </Button>
            </Drawer.Footer>
        </ResponsiveDrawer>
    );
};
