import {getCurrentDate} from "common/util/dateUtil";
import {cleanStringForFileName} from "common/util/downloadUtil";
import {
    endOfISOWeek,
    format,
    lastDayOfISOWeek,
    lastDayOfMonth,
    parse,
    startOfISOWeek,
    subDays,
    subWeeks,
} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";
import {getIsoDate, getIsoWeek} from "@fleet/common/utils/dateFormatUtils";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

export enum Period {
    ONGOING_DAY,
    PREVIOUS_7_DAYS,
    WEEK,
    MONTH,
    CUSTOM,
}

export interface OngoingDay {
    period: Period.ONGOING_DAY;
}

export interface Previous7Days {
    period: Period.PREVIOUS_7_DAYS;
}

export interface Week {
    period: Period.WEEK;
    week: string; // momentjs GGGG[W]WW or datefns RRRR'W'II
}

export interface Month {
    period: Period.MONTH;
    month: string; // momentjs YYYY-MM or datefns yyyy-MM
}

export interface Custom {
    period: Period.CUSTOM;
    startDate: Date;
    endDate: Date;
}

export type ApiPeriod = OngoingDay | Previous7Days | Week | Month | Custom;

export const getDatesStrFromDates = (startDate: Date, endDate: Date) => {
    return `${getIsoDate(startDate)}-${getIsoDate(endDate)}`;
};

export const apiPeriodToDates = (period: ApiPeriod): {start: Date; end: Date} => {
    const now = getCurrentDate();
    switch (period.period) {
        case Period.ONGOING_DAY:
            return {start: now, end: now};
        case Period.PREVIOUS_7_DAYS:
            return {start: subDays(now, 7), end: subDays(now, 1)};
        case Period.WEEK: {
            const startOfIsoWeek = parse(period.week, "RRRR'W'II", new Date());
            const endOfIsoWeek = lastDayOfISOWeek(startOfIsoWeek);
            return {start: startOfIsoWeek, end: endOfIsoWeek};
        }
        case Period.MONTH: {
            const startOfMonth = parse(period.month, "yyyy-MM", new Date());
            const endOfMonth = lastDayOfMonth(startOfMonth);
            return {start: startOfMonth, end: endOfMonth};
        }
        case Period.CUSTOM: {
            return {start: period.startDate, end: period.endDate};
        }
        default:
            return UnreachableCode.never(period, {start: now, end: now});
    }
};

export const datesToApiPeriod = (startDate: Date, endDate: Date): ApiPeriod => {
    const datesStr = getDatesStrFromDates(startDate, endDate);
    const now = new Date();

    if (datesStr === getDatesStrFromDates(now, now)) {
        return {period: Period.ONGOING_DAY} as OngoingDay;
    }
    if (datesStr === getDatesStrFromDates(subDays(now, 7), subDays(now, 1))) {
        return {period: Period.PREVIOUS_7_DAYS} as Previous7Days;
    }

    const lastWeekStartDate = subDays(startOfISOWeek(now), 7);
    const lastWeekEndDate = endOfISOWeek(lastWeekStartDate);
    if (datesStr === getDatesStrFromDates(lastWeekStartDate, lastWeekEndDate)) {
        const lastWeek = getIsoWeek(subWeeks(now, 1));
        return {period: Period.WEEK, week: lastWeek} as Week;
    }
    return {period: Period.CUSTOM, startDate, endDate} as Custom;
};

export const filePeriod = (period: ApiPeriod, intlLocale: string) => {
    if (period.period === Period.WEEK) {
        return period.week;
    }
    if (period.period === Period.MONTH) {
        return period.month;
    }
    const dateFnsLocale = intlLocaleToDateFnsLocale[intlLocale];
    const dates = apiPeriodToDates(period);
    const start = cleanStringForFileName(format(dates.start, "P", {locale: dateFnsLocale}));
    const end = cleanStringForFileName(format(dates.end, "P", {locale: dateFnsLocale}));
    return `${start}-${end}`;
};

export const datesToUnixTimestamps = (startDate: Date, endDate: Date): {start: number; end: number} => {
    return {start: startDate.getTime() / 1000, end: endDate.getTime() / 1000};
};

export const getPeriodName = (period: ApiPeriod) => {
    if (period.period === Period.ONGOING_DAY) {
        return "Ongoing day";
    }
    if (period.period === Period.MONTH) {
        return "Month";
    }
    if (period.period === Period.WEEK) {
        return "Week";
    }
    if (period.period === Period.PREVIOUS_7_DAYS) {
        return "Previous 7 days";
    }
    return "Custom";
};
