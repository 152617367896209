import {useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {Button, Checkbox, Dialog} from "@bolteu/kalep-react";

interface Props {
    isOpen: boolean;
    close: () => void;
    confirmDeactivation: (shouldDeclineApplications: boolean) => void;
    isLoading: boolean;
}

const DeactivateConfirmationDialog = ({isOpen, close, confirmDeactivation, isLoading}: Props) => {
    const {i18n} = useI18n();

    const [shouldDeclineAllApplications, setShouldDeclineAllApplications] = useState(false);

    const titleText = i18n("auth.app.fleet.vehicle_marketplace.listings.view.deactivate_application_dialog.title");
    const descriptionText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.deactivate_application_dialog.description",
    );
    const rejectAllApplicationsText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.deactivate_application_dialog.reject_all_applications_checkbox",
    );
    const confirmDeactivationText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.deactivate_application_dialog.confirm_button_text",
    );
    const cancelDeactivationText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.deactivate_application_dialog.cancel_button_text",
    );

    const confirmDeactivationDeclineApplications = useCallback(() => {
        confirmDeactivation(shouldDeclineAllApplications);
    }, [confirmDeactivation, shouldDeclineAllApplications]);

    const toggleDeclineAllApplications = useCallback(() => {
        setShouldDeclineAllApplications((prev) => !prev);
    }, []);

    return (
        <ResponsiveDialog title={titleText} isOpen={isOpen} onRequestClose={close}>
            <Dialog.Content>
                <p className="pb-4">{descriptionText}</p>
                <Checkbox
                    onClick={toggleDeclineAllApplications}
                    defaultChecked={shouldDeclineAllApplications}
                    label={rejectAllApplicationsText}
                />
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-center gap-4">
                    <Button variant="danger" onClick={confirmDeactivationDeclineApplications} loading={isLoading}>
                        {confirmDeactivationText}
                    </Button>
                    <Button variant="secondary" onClick={close}>
                        {cancelDeactivationText}
                    </Button>
                </div>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};

export default DeactivateConfirmationDialog;
