import {
    forwardRef,
    KeyboardEvent,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    UIEvent,
    UIEventHandler,
    useCallback,
    useMemo,
    useState,
} from "react";
import {useIntl} from "react-intl";
import {IntlShape} from "react-intl/src/types";
import {useNavigate} from "react-router-dom";

import {EventName} from "common/constants/events";
import {I18nFn, useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {TextButton} from "@fleet/common/components/TextButton";
import {getDatetimeString} from "@fleet/common/utils/dateFormatUtils";

import {FleetInboxService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";
import {Alert, CheckCircle, InfoCircle} from "@bolteu/kalep-react-icons";

const iconPlaceholder = <div className="w-6 shrink-0" />;

const getWrapperForRow = (node: ReactNode, className?: string, icon?: ReactNode) => {
    return (
        <div className={`flex flex-row items-center gap-3 ${className}`}>
            {icon ?? iconPlaceholder}
            {node}
        </div>
    );
};

const getIcon = (notification: FleetInboxService.LocalizedPortalNotification) => {
    switch (notification.icon) {
        case "info":
            return <InfoCircle className="text-content-tertiary shrink-0" />;
        case "success":
            return <CheckCircle className="text-content-action-secondary shrink-0" />;
        case "danger":
            return <Alert className="text-content-danger-secondary shrink-0" />;
        default:
            return <InfoCircle className="text-content-tertiary shrink-0" />;
    }
};

const getTitleRow = (
    notification: FleetInboxService.LocalizedPortalNotification,
    isUnread: boolean,
    groupSize?: number,
) => {
    const icon = getIcon(notification);
    const groupSizeIndicator = groupSize && groupSize > 1 ? ` (${groupSize})` : "";
    const titleText = `${notification.title}${groupSizeIndicator}`;
    const title = isUnread ? (
        <div className="flex flex-row flex-nowrap items-center gap-2">
            <div className="text-primary text-sm font-semibold">{titleText}</div>
            <div className="h-2 w-2 shrink-0 rounded-full bg-yellow-900" />
        </div>
    ) : (
        <div className="text-secondary text-sm font-normal">{titleText}</div>
    );
    return getWrapperForRow(title, "my-1", icon);
};
/**
 * This method is used to get the body text of the notification. If the notification has link and entity_identifier in the body_raw.parameters,
 * it will inject the entity_identifier as a clickable text button. All other parameters will be injected as plain text.
 * Downside of this solution is that there is no guarantee that the parameters and template placeholders are compatible.
 *
 * If the notification does not have entity_identifier, backend generated final text (notification.body) will be used.
 */
const getBodyText = (
    notification: FleetInboxService.LocalizedPortalNotification,
    callToActionCallback: (event: MouseEvent) => void,
) => {
    let bodyText: string | ReactNode = notification.body;
    if (notification.link && "entity_identifier" in notification.body_raw.parameters) {
        const identifier = notification.body_raw.parameters.entity_identifier as string;
        bodyText = notification.body_raw.text
            .split(/{(\w+)}/g)
            .filter(Boolean)
            .map((part) => {
                if (part === "entity_identifier") {
                    return (
                        <TextButton
                            key={identifier}
                            text={identifier}
                            onClick={callToActionCallback}
                            className="text-sm font-normal"
                        />
                    );
                }
                if (part in notification.body_raw.parameters) {
                    const partValue = String(notification.body_raw.parameters[part]);
                    return <span key={partValue}>{partValue}</span>;
                }
                return <span key={part}>{part}</span>;
            });
    }
    return bodyText;
};
const getBodyRow = (
    notification: FleetInboxService.LocalizedPortalNotification,
    isBodyExpanded: boolean,
    callToActionCallback: (event: MouseEvent) => void,
) => {
    const bodyText = getBodyText(notification, callToActionCallback);
    return getWrapperForRow(
        <div
            className={`text-secondary whitespace-pre-line text-sm font-normal ${isBodyExpanded ? "" : "line-clamp-2"}`}
        >
            {bodyText}
        </div>,
        "mb-2",
    );
};
const getTimestampRow = (intl: IntlShape, notification: FleetInboxService.LocalizedPortalNotification) =>
    getWrapperForRow(
        <div className="text-secondary text-xs font-normal">
            {getDatetimeString(intl, notification.message_created)}
        </div>,
    );

const getCallToActionRow = (
    i18n: I18nFn,
    notification: FleetInboxService.LocalizedPortalNotification,
    onClick: MouseEventHandler<HTMLButtonElement>,
    expandButtonProps?: ExpandButtonProps,
) => {
    const callToActionButton =
        notification.link && !("entity_identifier" in notification.body_raw.parameters) ? (
            <Button size="sm" variant="secondary" onClick={onClick}>
                {notification.link.text}
            </Button>
        ) : null;
    const expandButton = expandButtonProps ? (
        <Button size="sm" variant="secondary" onClick={expandButtonProps.onExpand}>
            {i18n("auth.app.notifications.nr_more_notifications", {count: expandButtonProps.hiddenCount})}
        </Button>
    ) : null;
    if (callToActionButton || expandButton) {
        return getWrapperForRow(
            <div className="flex flex-row gap-2">
                {callToActionButton}
                {expandButton}
            </div>,
            "mt-3 mb-1",
        );
    }
    return null;
};

export interface ExpandButtonProps {
    hiddenCount: number;
    onExpand: UIEventHandler;
}

export interface TitleRow {
    isUnread: boolean;
    groupSize: number;
}

export interface NotificationListItemProps {
    notification: FleetInboxService.LocalizedPortalNotification;
    onItemClick?: UIEventHandler;
    onCallToActionClick?: UIEventHandler;
    titleRow?: TitleRow;
    expandButton?: ExpandButtonProps;
    isPartOfMenu: boolean;
}

const NotificationListItem = forwardRef<HTMLDivElement, NotificationListItemProps>(
    (
        {
            notification,
            onItemClick,
            onCallToActionClick,
            titleRow,
            expandButton,
            isPartOfMenu,
        }: NotificationListItemProps,
        ref,
    ) => {
        const {trackEvent} = useTracking();
        const intl = useIntl();
        const {i18n} = useI18n();
        const navigate = useNavigate();
        const [isBodyRowExpanded, setIsBodyRowExpanded] = useState(false);
        const onItemClickWrapper = useCallback(
            async (e: MouseEvent | KeyboardEvent) => {
                setIsBodyRowExpanded(!isBodyRowExpanded);
                onItemClick?.(e);
            },
            [isBodyRowExpanded, onItemClick],
        );

        const onCallToActionClickWrapper = useCallback(
            async (e: UIEvent) => {
                if (notification.link) {
                    trackEvent(EventName.NotificationCallToActionClicked);
                    navigate(`${isPartOfMenu ? "" : "../"}${notification.link.url}`);
                }
                onCallToActionClick?.(e);
                e.stopPropagation();
            },
            [notification.link, onCallToActionClick, trackEvent, navigate, isPartOfMenu],
        );

        const expandButtonWrapper = useMemo(() => {
            if (!expandButton) {
                return undefined;
            }
            const onExpandWrapper = (e: UIEvent) => {
                expandButton.onExpand(e);
                e.stopPropagation();
            };
            return {
                hiddenCount: expandButton.hiddenCount,
                onExpand: onExpandWrapper,
            };
        }, [expandButton]);

        return (
            <div
                ref={ref}
                className="hover:bg-neutral-secondary flex flex-row gap-3 py-3 px-6"
                role="button"
                onClick={onItemClickWrapper}
                onKeyDown={onItemClickWrapper}
                tabIndex={0}
            >
                <div className="flex flex-col">
                    {titleRow && getTitleRow(notification, titleRow.isUnread, titleRow.groupSize)}
                    {getBodyRow(notification, isBodyRowExpanded, onCallToActionClickWrapper)}
                    {getTimestampRow(intl, notification)}
                    {getCallToActionRow(i18n, notification, onCallToActionClickWrapper, expandButtonWrapper)}
                </div>
            </div>
        );
    },
);

export default NotificationListItem;
