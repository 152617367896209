import {FC} from "react";

import {iconNameToKalepIcon} from "@fleet/common/utils/iconNameToKalepIcon";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";

import ApiChip = FleetOwnerPortalService.Chip;

interface ChipRowProps {
    chips: ApiChip[];
}

const ChipRow: FC<ChipRowProps> = ({chips}) => {
    return (
        <div className="flex gap-2">
            {chips.map((chip) => {
                const ChipIcon = chip.icon && iconNameToKalepIcon(chip.icon.kalep_name);
                return (
                    <Chip
                        key={chip.label}
                        size="sm"
                        startIcon={ChipIcon && <ChipIcon />}
                        label={chip.label}
                        color={chip.kalep_variant}
                    />
                );
            })}
        </div>
    );
};

export default ChipRow;
