import {useMemo} from "react";

import {CustomizableAccordionTableHeaders} from "common/components/table/AccordionTableWithCustomizableColumns";
import {KalepAccordionTableDataAndMeasuresCell} from "common/components/table/DataAndMeasuresCell";
import {useI18n} from "common/hooks/useI18n";
import {Dash} from "@fleet/common/constants/dash";

import {DriverEarningsService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {AccordionHeaders} from "@bolteu/kalep-table-react";

import {ReportsTableData} from "..";

export function useAccordionTableColumns(
    currency: string,
    configuration: DriverEarningsService.GetDriverEarningsColumnsConfiguration | undefined,
    showActualPayouts: boolean,
): AccordionHeaders<ReportsTableData> {
    const {i18n} = useI18n();

    const vatTranslation = i18n("auth.app.invoices.rider-invoices.csv.vat");

    return useMemo<CustomizableAccordionTableHeaders<ReportsTableData>>(() => {
        const sumPerHourUnit = `${currency}/${i18n("common.hour_abbreviation")}`;

        return {
            // Earnings
            gross_revenue: {
                label: i18n("auth.app.fleet.reports.header.gross_revenue"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.gross_revenue"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.gross_revenue} measure={currency} />
                ),
            },
            gross_revenue_app: {
                label: i18n("auth.app.fleet.reports.header.gross_revenue_app"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.gross_revenue_app"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_revenue_in_app && item.vat_sum_revenue_in_app !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_revenue_in_app}`;
                    }
                    return (
                        <KalepAccordionTableDataAndMeasuresCell
                            data={item.gross_revenue_app}
                            measure={currency}
                            extraData={extraData}
                        />
                    );
                },
            },
            gross_revenue_cash: {
                label: i18n("auth.app.fleet.reports.header.gross_revenue_cash"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.gross_revenue_cash"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_revenue_cash && item.vat_sum_revenue_cash !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_revenue_cash}`;
                    }
                    return (
                        <KalepAccordionTableDataAndMeasuresCell
                            data={item.gross_revenue_cash}
                            measure={currency}
                            extraData={extraData}
                        />
                    );
                },
            },
            cash_in_hand: {
                label: i18n("auth.app.fleet.reports.header.cash_in_hand"),
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.cash_in_hand} measure={currency} />
                ),
            },
            tips: {
                label: i18n("auth.app.fleet.reports.header.tips"),
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.tips} measure={currency} />
                ),
            },
            bonuses: {
                label: i18n("auth.app.fleet.reports.cards.bonuses"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.bonuses"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_bonuses && item.vat_sum_bonuses !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_bonuses}`;
                    }
                    return (
                        <KalepAccordionTableDataAndMeasuresCell
                            data={item.bonuses}
                            measure={currency}
                            extraData={extraData}
                        />
                    );
                },
            },
            compensations: {
                label: i18n("auth.app.fleet.reports.compensations"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.compensations"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_compensations && item.vat_sum_compensations !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_compensations}`;
                    }
                    return (
                        <KalepAccordionTableDataAndMeasuresCell
                            data={item.compensations}
                            measure={currency}
                            extraData={extraData}
                        />
                    );
                },
            },
            cancellation_fees: {
                label: i18n("auth.app.fleet.reports.cancellation_fees"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.cancellation_fees"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (
                        configuration?.is_show_vat_sum_cancellation_fees &&
                        item.vat_sum_cancellation_fees !== Dash.EM
                    ) {
                        extraData = `${vatTranslation} ${item.vat_sum_cancellation_fees}`;
                    }
                    return (
                        <KalepAccordionTableDataAndMeasuresCell
                            data={item.cancellation_fees}
                            measure={currency}
                            extraData={extraData}
                        />
                    );
                },
            },
            toll_roads: {
                label: i18n("auth.app.fleet.reports.cards.toll_roads"),
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.toll_roads} measure={currency} />
                ),
                hideByDefault: true,
            },
            booking_fees: {
                label: i18n("auth.app.fleet.reports.cards.booking_fees"),
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_booking_fees && item.vat_sum_booking_fees !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_booking_fees}`;
                    }

                    return (
                        <KalepAccordionTableDataAndMeasuresCell
                            data={item.booking_fees}
                            measure={currency}
                            extraData={extraData}
                        />
                    );
                },
                hideByDefault: true,
            },
            // Expenses
            expenses_total: {
                label: i18n("auth.app.fleet.reports.expenses_total"),
                tooltip: i18n("auth.app.fleet.reports.expenses_total_explanation"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.expenses_total} measure={currency} />
                ),
                hideByDefault: true,
                direction: "horizontal",
            },
            expense_commissions: {
                label: i18n("auth.app.fleet.reports.expense_commissions"),
                tooltip: i18n("auth.app.fleet.reports.expense_commissions_explanation"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.expense_commissions} measure={currency} />
                ),
                hideByDefault: true,
                direction: "horizontal",
            },
            expense_refunds: {
                label: i18n("auth.app.fleet.reports.expense_refunds"),
                tooltip: i18n("auth.app.fleet.reports.expense_refunds_explanation"),
                tooltipProps: {
                    placement: "bottom",
                },
                hideByDefault: true,
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.expense_refunds} measure={currency} />
                ),
            },
            expense_other_fees: {
                label: i18n("auth.app.fleet.reports.expense_other_fees"),
                tooltip: i18n("auth.app.fleet.reports.expense_other_fees_explanation"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.expense_other_fees} measure={currency} />
                ),
                hideByDefault: true,
                direction: "horizontal",
            },
            // Net
            net_earnings: {
                label: i18n("auth.app.fleet.reports.header.net_earnings"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.net_earnings"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.net_earnings} measure={currency} />
                ),
            },
            payouts: {
                label: i18n("auth.app.fleet.reports.projected_payout"),
                tooltip: i18n("auth.app.fleet.reports.projected_payout_explanation"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell data={item.payouts} measure={currency} />
                ),
                hideByDefault: true,
                direction: "horizontal",
            },
            ...(showActualPayouts && {
                actual_payouts: {
                    label: i18n("auth.app.fleet.reports.actual_payout"),
                    tooltip: i18n("auth.app.fleet.reports.actual_payout_explanation"),
                    tooltipProps: {
                        placement: "bottom",
                    },
                    renderCell: (item: ReportsTableData) => (
                        <KalepAccordionTableDataAndMeasuresCell data={item.actual_payouts} measure={currency} />
                    ),
                    hideByDefault: true,
                    direction: "horizontal",
                },
            }),
            // Earnings indicators
            earnings_per_hour_gross: {
                label: i18n("auth.app.fleet.reports.cards.eph_gross"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.earnings_per_hour_gross"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={item.earnings_per_hour_gross}
                        measure={sumPerHourUnit}
                    />
                ),
            },
            earnings_per_hour_net: {
                label: i18n("auth.app.fleet.reports.cards.eph_net"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.earnings_per_hour_net"),
                tooltipProps: {
                    placement: "bottom",
                },
                direction: "horizontal",
                renderCell: (item: ReportsTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={item.earnings_per_hour_net}
                        measure={sumPerHourUnit}
                    />
                ),
            },
            // Other indicators
            ...(configuration?.is_show_withholding_tax && {
                withholding_tax: {
                    label: i18n("auth.app.fleet.balance.transactions_list.earnings.withholding_tax.title"),
                    renderCell: (item: ReportsTableData) => (
                        <KalepAccordionTableDataAndMeasuresCell data={item.withholding_tax} measure={currency} />
                    ),
                    direction: "horizontal",
                },
            }),
            uuid: {
                label: i18n("auth.app.fleet.driver.uuid"),
                renderCell: (item: ReportsTableData) => (
                    <>
                        {item.uuid ? (
                            <div className="text-base">{item.uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </>
                ),
                hideByDefault: true,
                tooltip: i18n("auth.app.fleet.driver.uuid_tooltip"),
            },
            partner_uuid: {
                label: i18n("auth.app.fleet.driver.partner_uuid"),
                renderCell: (item: ReportsTableData) => (
                    <>
                        {item.partner_uuid ? (
                            <div className="text-base">{item.partner_uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </>
                ),
                hideByDefault: true,
                tooltip: i18n("auth.app.fleet.driver.partner_uuid_tooltip"),
            },
        };
    }, [currency, i18n, configuration, vatTranslation, showActualPayouts]);
}
