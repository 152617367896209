import {useMemo} from "react";

import {Api} from "common/services/apiProvider";
import {ApiPeriod, Period} from "common/services/period";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {DriverEarningsService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export function useCompanyEarningsApi(
    period: ApiPeriod,
): (api: Api) => Promise<DriverEarningsService.GetCompanyEarningsResponse> {
    return useMemo(() => {
        switch (period.period) {
            case Period.ONGOING_DAY:
                return (api: Api) => {
                    return api.driverEarnings.getCompanyEarningsRecent({
                        period: DriverEarningsService.RecentEarningsPeriod.ONGOING_DAY,
                    });
                };
            case Period.PREVIOUS_7_DAYS:
                return (api: Api) => {
                    return api.driverEarnings.getCompanyEarningsRecent({
                        period: DriverEarningsService.RecentEarningsPeriod.PREVIOUS_7_DAYS,
                    });
                };
            case Period.WEEK:
                return (api: Api) => {
                    return api.driverEarnings.getCompanyEarningsWeek({
                        week: period.week,
                    });
                };
            case Period.MONTH:
                return (api: Api) => {
                    return api.driverEarnings.getCompanyEarningsMonth({
                        month: period.month,
                    });
                };
            case Period.CUSTOM:
                return (api: Api) => {
                    return api.driverEarnings.getCompanyEarningsDateRange({
                        start_date: getIsoDate(period.startDate),
                        end_date: getIsoDate(period.endDate),
                    });
                };
            default:
                return UnreachableCode.never(period, async () => {
                    return {
                        net_earnings: 0,
                        gross_revenue_total: 0,
                        earnings_per_hour_gross: 0,
                        earnings_per_hour_net: 0,
                        bonuses: 0,
                        compensations: 0,
                        total_expenses: 0,
                        currency_symbol: "",
                    };
                });
        }
    }, [period]);
}
