import {FC} from "react";
import {useIntl} from "react-intl";

import {formatCurrencyValue} from "@fleet/common/utils/currencyFormatUtils";

import TransactionCardGraphSection from "./TransactionCardGraphSection";
import {GraphData} from "./types";

interface TransactionCardGraphProps {
    graphData: GraphData;
    currencySymbol: string;
    colorClasses: string[];
    isMobileView: boolean;
}

const TransactionCardGraph: FC<TransactionCardGraphProps> = ({
    graphData,
    currencySymbol,
    colorClasses,
    isMobileView,
}) => {
    const intl = useIntl();

    const totalText = `${formatCurrencyValue(intl, graphData.total)} ${currencySymbol}`;

    return (
        <div className="flex items-center gap-2">
            <div style={{flexGrow: graphData.widthScale}} className="flex basis-0">
                <div className="flex w-full gap-0.5 overflow-hidden rounded-full">
                    {graphData.sections.map((section, i) => (
                        <TransactionCardGraphSection
                            key={section.name}
                            name={section.name}
                            value={section.value}
                            width={section.value / graphData.total}
                            colorClass={colorClasses[i]}
                            isMobileView={isMobileView}
                            currencySymbol={currencySymbol}
                        />
                    ))}
                </div>
            </div>
            <span className="whitespace-nowrap text-sm">{totalText}</span>
        </div>
    );
};

export default TransactionCardGraph;
