import {useCallback, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";
import {SupportWidgetContextProvider} from "SupportWidgetProvider";

import {GhostButton, Link, Typography} from "@bolteu/kalep-react";
import {BoltFontStyles, TypographyColor} from "@bolteu/kalep-react/build/components/Typography";

interface CustomerSupportContactProps {
    csWidgetKey: TranslationKeys;
    csWidgetLinkPlaceholder?: string;
    customerSupportEmail?: string | null;
    emailKey: TranslationKeys;
    emailLinkPlaceholder?: string;
    fallbackKey: TranslationKeys;
    textVariant?: BoltFontStyles;
    textColor?: TypographyColor;
    onCsClick?: () => void;
}

export const CustomerSupportContact = (props: CustomerSupportContactProps) => {
    const {i18n} = useI18n();
    const {openSupportWidget, isSupportWidgetEnabled} = useContext(SupportWidgetContextProvider);
    const {onCsClick} = props;
    const textVariant = props.textVariant ?? "body-secondary";
    const textColor = props.textColor ?? "secondary";

    const onCsWidgetClick = useCallback(() => {
        openSupportWidget();
        if (onCsClick) {
            onCsClick();
        }
    }, [onCsClick, openSupportWidget]);

    let customerSupportText;

    if (isSupportWidgetEnabled) {
        customerSupportText = i18n(props.csWidgetKey, {
            [props.csWidgetLinkPlaceholder ?? "customer_support"]: (
                <GhostButton onClick={onCsWidgetClick}>
                    <Typography color="link-primary" variant={textVariant}>
                        {i18n("common.customer_support")}
                    </Typography>
                </GhostButton>
            ),
        });
    } else if (props.customerSupportEmail) {
        customerSupportText = i18n(props.emailKey, {
            [props.emailLinkPlaceholder ?? "email"]: (
                <Link href={`mailto:${props.customerSupportEmail}`}>{props.customerSupportEmail}</Link>
            ),
        });
    } else {
        customerSupportText = i18n(props.fallbackKey, {customer_support: i18n("common.customer_support")});
    }

    return (
        <>
            <Typography variant={textVariant} color={textColor}>
                {customerSupportText}
            </Typography>
        </>
    );
};
