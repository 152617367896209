import {useCallback, useContext, useEffect} from "react";

import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {CategoriesContextProvider} from "../../../CategoriesContextProvider";
import {useCreateOrderValidations} from "../../../hooks/useCreateOrderValidations";
import {CreateDrawerType, Step1Data, Step2Data} from "../../../types";
import {Categories} from "../categories";
import {ManualPrice} from "../ManualPrice";
import {PaymentMethods} from "../PaymentMethods";

interface Props {
    data: Step1Data & Step2Data;
    onChange: (data: Partial<Step2Data>) => void;
    validationErrors?: FleetPortalOrderService.ValidationError[];
    drawerType: CreateDrawerType;
}

const FIND_SCHEDULED_RIDE_OPTIONS_INTERVAL_MS = 30_000;
const FIND_INSTANT_RIDE_OPTIONS_INTERVAL_MS = 15_000;

export const RideDetails = ({drawerType, data, onChange, validationErrors}: Props) => {
    const scheduledRidesContext = useContext(ScheduledRidesContextProvider);
    const {
        rideOptions,
        findRideOptions,
        isLoading: isLoadingCategories,
        apiError,
    } = useContext(CategoriesContextProvider);

    const {validateManualPricing} = useCreateOrderValidations(drawerType);

    const getCategories = useCallback(() => {
        if (!data.pickup.lat || !data.pickup.lng) {
            // Should exist from previous step
            return;
        }
        const pickup = {...data.pickup, lat: data.pickup.lat, lng: data.pickup.lng};
        findRideOptions(
            {
                payment_method: data.payment_method,
                is_manual_price_enabled: data.is_manual_price_enabled,
                scheduled_for: data.scheduled_for,
                pickup,
                dropoffs: data.dropoffs,
                create_order_uuid: data.create_order_uuid,
            },
            drawerType,
        );
    }, [
        data.create_order_uuid,
        data.dropoffs,
        data.is_manual_price_enabled,
        data.payment_method,
        data.pickup,
        data.scheduled_for,
        drawerType,
        findRideOptions,
    ]);

    useEffect(() => {
        getCategories();
        const intervalMs =
            drawerType === CreateDrawerType.SCHEDULE
                ? FIND_SCHEDULED_RIDE_OPTIONS_INTERVAL_MS
                : FIND_INSTANT_RIDE_OPTIONS_INTERVAL_MS;
        const interval = setInterval(() => getCategories(), intervalMs);
        return () => clearInterval(interval);
    }, [drawerType, getCategories]);

    useEffect(() => {
        if (data.category_id && rideOptions) {
            const selectedCategory = rideOptions.categories.find((category) => category.id === data.category_id);
            if (!selectedCategory) {
                onChange({category_id: undefined, category_price: undefined});
            }
        }
    }, [data.category_id, onChange, rideOptions]);

    const onPaymentMethodChange = useCallback(
        (newPaymentMethod: FleetPortalOrderService.CreateOrderPaymentMethod) => {
            onChange({payment_method: newPaymentMethod});
        },
        [onChange],
    );

    const onCategoryChange = useCallback(
        (category: {id?: number; price?: string; order_duration_in_seconds?: number}) => {
            onChange({
                category_id: category.id,
                category_price: category.price,
                category_ride_duration_seconds: category.order_duration_in_seconds,
            });
        },
        [onChange],
    );

    const onManualPriceChange = useCallback(
        (price: string) => {
            onChange({manual_price: price});
        },
        [onChange],
    );

    const onManualPriceSwitchChange = useCallback(() => {
        onChange({is_manual_price_enabled: !data.is_manual_price_enabled});
    }, [data.is_manual_price_enabled, onChange]);

    const manualPrice = data.manual_price !== undefined ? data.manual_price : data.category_price ?? "";
    const isManualPriceEnabledAndEntered = data.is_manual_price_enabled && data.manual_price !== undefined;
    const categoryError = validationErrors?.find((error) => error.property === "category_id")?.error ?? apiError?.error;
    const manualPriceError =
        validationErrors?.find((error) => error.property === "manual_price")?.error ??
        validateManualPricing(data.is_manual_price_enabled, data.manual_price, data.category_price)?.error;

    return (
        <div className="flex flex-col gap-4">
            <PaymentMethods selectedPaymentMethod={data.payment_method} onChange={onPaymentMethodChange} />
            <Categories
                selectedCategoryId={data.category_id}
                onChange={onCategoryChange}
                categories={rideOptions?.categories}
                isLoading={isLoadingCategories}
                categoryError={categoryError}
                isManualPriceEnabledAndEntered={isManualPriceEnabledAndEntered}
            />
            {!!scheduledRidesContext.getManualPricing().is_enabled && (
                <ManualPrice
                    isManualPriceEnabled={data.is_manual_price_enabled}
                    onManualPriceChange={onManualPriceChange}
                    onManualPriceSwitchChange={onManualPriceSwitchChange}
                    manualPrice={manualPrice}
                    validationError={manualPriceError}
                />
            )}
        </div>
    );
};
