import {useCallback, useContext, useEffect, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod, Period} from "common/services/period";
import {getCurrentDate} from "common/util/dateUtil";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {FeaturesContextProvider} from "FeaturesProvider";
import {getIsoWeek} from "@fleet/common/utils/dateFormatUtils";

import BalanceCardsRow from "./components/BalanceCardsRow";
import BalancePageHeader from "./components/BalancePageHeader";
import Transactions from "./components/Transactions";

const BalancePage = () => {
    const features = useContext(FeaturesContextProvider);

    const {i18n} = useI18n();

    const thisWeek = getIsoWeek(getCurrentDate());
    const [period, setPeriod] = useState<ApiPeriod>({period: Period.WEEK, week: thisWeek});

    const onPeriodChange = useCallback(
        (newPeriod: ApiPeriod) => {
            setPeriod(newPeriod);
        },
        [setPeriod],
    );

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.balance.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (!features?.balance) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return (
        <Page className="gap-y-0">
            <BalancePageHeader />
            <div className="pt-6 pb-4">
                <BalanceCardsRow />
            </div>
            <Transactions period={period} onPeriodChange={onPeriodChange} />
        </Page>
    );
};

export default BalancePage;
