import {Dash} from "@fleet/common/constants/dash";

import {Typography} from "@bolteu/kalep-react";
import {Check} from "@bolteu/kalep-react-icons";

export function BooleanCellContent({value, isSubCell}: {value: boolean; isSubCell?: boolean}): React.ReactElement {
    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    return (
        <div className="align-right flex justify-start gap-1 whitespace-nowrap text-left">
            <div className="items-baseline gap-x-1">
                <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                    {value ? <Check className="text-secondary" /> : <span className="text-tertiary">{Dash.EM}</span>}
                </Typography>
            </div>
        </div>
    );
}
