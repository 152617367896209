import React from "react";

import {CustomerSupportContact} from "common/components/customerSupport/CustomerSupportContact";
import DisplayField from "common/components/displayField/DisplayField";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import GetCompanyDetailsResponse = FleetOwnerPortalService.GetCompanyDetailsResponse;
import UpdateCompanyDetailsRequest = FleetOwnerPortalService.UpdateCompanyDetailsRequest;

interface CompanyDetailsTabProps {
    companyData: GetCompanyDetailsResponse | null;
}

const fetchFunction = (api: Api, data: UpdateCompanyDetailsRequest) => api.fleetOwnerPortal.updateCompanyDetails(data);

const CompanyDetailsTab: React.FC<CompanyDetailsTabProps> = ({companyData}) => {
    const {i18n} = useI18n();

    if (!companyData) {
        return null;
    }

    const cityTitle = i18n("auth.app.company-settings.company_details.city");
    const addressTitle = i18n("auth.app.company-settings.company_details.address");
    const emailTitle = i18n("auth.app.company-settings.company_details.email");
    const phoneTitle = i18n("auth.app.company-settings.company_details.phone");
    const regCodeTitle = i18n("auth.app.company-settings.company_details.reg_code");

    const isEmailFieldEditable = companyData.editable_fields_config.email;
    const isPhoneFieldEditable = companyData.editable_fields_config.phone;

    return (
        <div className="md:w-5/12">
            <div className="mb-4">
                <DisplayField title={cityTitle} value={companyData.city} />
                <DisplayField title={addressTitle} value={companyData.address} />
                {isEmailFieldEditable ? (
                    <DisplayField
                        isEditable
                        fieldName="email"
                        title={emailTitle}
                        value={companyData.email}
                        updateFetchFunction={fetchFunction}
                    />
                ) : (
                    <DisplayField title={emailTitle} value={companyData.email} />
                )}
                {isPhoneFieldEditable ? (
                    <DisplayField
                        isEditable
                        fieldName="phone"
                        title={phoneTitle}
                        value={companyData.phone}
                        updateFetchFunction={fetchFunction}
                    />
                ) : (
                    <DisplayField title={phoneTitle} value={companyData.phone} />
                )}
                <DisplayField title={regCodeTitle} value={companyData.reg_code} />
            </div>
            <CustomerSupportContact
                customerSupportEmail={companyData.customer_support_email}
                csWidgetKey="auth.app.company-settings.contact_support_block.support_widget_enabled"
                emailKey="auth.app.company-settings.contact_support_block.customer_support_email_provided"
                emailLinkPlaceholder="email"
                fallbackKey="auth.app.company-settings.contact_support_block.no_customer_support_email"
            />
        </div>
    );
};

export default CompanyDetailsTab;
