import {useCallback, useEffect, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import config from "config/index";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {Button, Dialog} from "@bolteu/kalep-react";

const RefreshAppDialog = () => {
    const [isRefreshAppDialogVisible, setRefreshAppDialogVisible] = useState(false);

    const {i18n} = useI18n();
    const refresh = useCallback(async () => {
        window.location.reload();
    }, []);

    const compareAppVersions = useCallback(async () => {
        let response: Response;

        try {
            response = await fetch(config.versionFile);
        } catch (e) {
            return;
        }

        if (!response.ok) {
            if (!config.isStageLive) {
                throw new Error("Can't get version file");
            } else {
                return;
            }
        }

        const latestVersion = await response.text();
        if (!latestVersion) {
            if (!config.isStageLive) {
                throw new Error("Can't get version");
            } else {
                return;
            }
        }

        if (config.versionNumber.trim() !== latestVersion.trim()) {
            setRefreshAppDialogVisible(true);
        }
    }, []);

    const closeModal = useCallback(async () => {
        setRefreshAppDialogVisible(false);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => compareAppVersions(), config.compareVersionsInterval);
        return () => {
            clearInterval(interval);
        };
    }, [compareAppVersions]);

    return (
        <ResponsiveDialog
            title={i18n("auth.app.fleet.refresh-dialog.title")}
            isOpen={isRefreshAppDialogVisible}
            onRequestClose={closeModal}
        >
            <Dialog.Content>
                <div className="text-base text-neutral-700">{i18n("auth.app.fleet.refresh-dialog.description")}</div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={closeModal}>
                        {i18n("auth.app.fleet.refresh-dialog.cancel")}
                    </Button>
                    <Button onClick={refresh}>{i18n("auth.app.fleet.refresh-dialog.refresh")}</Button>
                </div>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};

export default RefreshAppDialog;
