import {useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox} from "@bolteu/kalep-react";

import {useAssigmentDrivers} from "../../hooks/useAssigmentDrivers";

interface Props {
    paymentMethod?: FleetPortalOrderService.CreateOrderPaymentMethod;
    categoryId?: number;
    disabled?: boolean;
    selectedDriverId?: number | null;
    selectedDriverName?: string;
    onChange: (driverId?: number, newName?: string) => void;
    validationError?: string;
}

const getDriversForOrderCreationAssignmentFunction = (
    api: Api,
    body: FleetPortalOrderService.GetDriversForOrderCreationAssignment,
) => api.fleetPortalOrder.getDriversForOrderCreationAssignment(body);

export const DriverComboBoxForOrderCreationAssignment = ({
    paymentMethod,
    categoryId,
    disabled,
    selectedDriverId,
    selectedDriverName,
    onChange,
    validationError,
}: Props) => {
    const {i18n} = useI18n();
    const {fetch: getDrivers, data, status} = useFetch(getDriversForOrderCreationAssignmentFunction);

    const {onDriverChange, driversOptions} = useAssigmentDrivers({
        fetchData: data,
        fetchStatus: status,
        selectedDriverName,
        onChange,
    });

    useEffect(() => {
        if (getDrivers && paymentMethod && categoryId) {
            getDrivers({payment_method: paymentMethod, category_id: categoryId});
        }
    }, [categoryId, getDrivers, paymentMethod]);

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(status);
    const selectedDriverOption = driversOptions?.find((option) => option.value === selectedDriverId);

    return (
        <ComboBox
            placeholder={i18n("auth.app.orders.scheduled_rides.select-driver-placeholder")}
            value={selectedDriverOption}
            options={driversOptions ?? []}
            onChange={onDriverChange}
            fullWidth
            disabled={disabled || isLoading}
            loading={isLoading}
            error={Boolean(validationError)}
            helperText={validationError}
        />
    );
};
