import {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {Dash} from "@fleet/common/constants/dash";
import {iconNameToKalepIcon} from "@fleet/common/utils/iconNameToKalepIcon";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip, Tooltip} from "@bolteu/kalep-react";
import {Check as CheckIcon} from "@bolteu/kalep-react-icons";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {getUniqueColumnIndex} from "../utils/getUniqueColumnIndex";
import {TableRow} from "./useTableRows";

import Column = FleetOwnerPortalService.Column;
import ColumnType = FleetOwnerPortalService.ColumnType;
import TableCell = FleetOwnerPortalService.TableCell;
import EntityType = FleetOwnerPortalService.EntityType;

enum Alignment {
    Left = "left",
    Right = "right",
}

function getAlignmentForColumn(column: Column): Alignment {
    switch (column.type) {
        case ColumnType.PERIOD:
        case ColumnType.ENTITY_NAME:
        case ColumnType.CHIP:
        case ColumnType.BOOLEAN:
        case ColumnType.ICON:
            return Alignment.Left;
        case ColumnType.TEXT: {
            const nonNullRows = column.rows.filter(({value}) => value !== null);

            if (nonNullRows.length === 0) {
                return Alignment.Right;
            }

            const alignment = nonNullRows.reduce((acc, {value, sidetext_left, sidetext_right}) => {
                // In case of an icon, keep the current alignment
                if (typeof value === "object") {
                    return acc;
                }

                const hasSideText = sidetext_left || sidetext_right;
                const valueStartsWithNumber = value && parseInt(value, 10);

                if (hasSideText || valueStartsWithNumber) {
                    return Alignment.Right;
                }

                return acc;
            }, Alignment.Left);

            return alignment;
        }
        default:
            return UnreachableCode.never(column, Alignment.Left);
    }
}

function renderColumnLabel(title: string, subtitle?: string) {
    return (
        <div>
            <div>{title}</div>
            <div className="text-sm font-normal text-neutral-700">{subtitle}</div>
        </div>
    );
}

export function useTableColumns(columns: Column[]): TableHeaders<TableRow> {
    const intl = useIntl();
    const {getDriverDetailsPath, getVehicleDetailsPath} = useAbsolutePath();

    const getCellBodyByColumnType = useCallback(
        (item: TableCell) => {
            switch (item.type) {
                case ColumnType.TEXT:
                    return item.value;
                case ColumnType.BOOLEAN:
                    return item.value ? <CheckIcon /> : null;
                case ColumnType.PERIOD:
                    return intl.formatDateTimeRange(item.value.start * 1000, item.value.end * 1000, {
                        dateStyle: "medium",
                        timeStyle: "short",
                    });
                case ColumnType.ENTITY_NAME: {
                    let linkTo = `${item.entity_type}/${item.entity_id}`;
                    if (item.entity_type === EntityType.DRIVER) {
                        linkTo = getDriverDetailsPath(item.entity_id);
                    } else if (item.entity_type === EntityType.CAR) {
                        linkTo = getVehicleDetailsPath(item.entity_id);
                    }

                    return (
                        <Link className="hover:underline" to={linkTo}>
                            {item.value}
                        </Link>
                    );
                }
                case ColumnType.CHIP:
                    return <Chip size="sm" label={item.value.label} color={item.value.kalep_variant} />;
                case ColumnType.ICON: {
                    const Icon = iconNameToKalepIcon(item.value.kalep_name);

                    if (item.value.tooltip) {
                        return (
                            <Tooltip content={item.value.tooltip} placement="top">
                                <div>
                                    <Icon className="text-secondary" width="100%" />
                                </div>
                            </Tooltip>
                        );
                    }

                    return <Icon className="text-secondary" width="100%" />;
                }
                default:
                    return UnreachableCode.never(item, null);
            }
        },
        [getDriverDetailsPath, getVehicleDetailsPath, intl],
    );

    const renderCell = useCallback(
        (row: TableRow, uniqueColumnIndex: string) => {
            const item = row[uniqueColumnIndex];
            if (typeof item === "number") {
                // Skip ID cell - no need to render it
                return null;
            }

            const cellClasses = "flex min-h-[56px] flex-col justify-center px-3 py-2";
            const Body = getCellBodyByColumnType(item);
            if (!Body) {
                return <div className={`text-content-tertiary text-end ${cellClasses}`}>{Dash.EM}</div>;
            }

            // TextWithDescriptionCellContent should be used here, but it doesn't have all the features yet
            return (
                <div className={cellClasses}>
                    <div>
                        {item.sidetext_left && (
                            <span className="pr-0.5 text-xs text-neutral-700">{item.sidetext_left}</span>
                        )}
                        <span>{Body}</span>
                        {item.sidetext_right && (
                            <span className="pl-0.5 text-xs text-neutral-700">{item.sidetext_right}</span>
                        )}
                    </div>
                    <span className="text-xs text-neutral-700">{item.subtext}</span>
                </div>
            );
        },
        [getCellBodyByColumnType],
    );

    return useMemo(
        () =>
            columns.reduce((acc, column) => {
                const {title, subtitle, tooltip, type} = column;
                const alignment = getAlignmentForColumn(column);

                const uniqueColumnIndex = getUniqueColumnIndex(type, title);
                acc[uniqueColumnIndex] = {
                    label: renderColumnLabel(title, subtitle),
                    align: alignment,
                    sortable: true,
                    tooltip,
                    renderCell: (row) => renderCell(row, uniqueColumnIndex),
                };
                return acc;
            }, {} as TableHeaders<TableRow>),
        [columns, renderCell],
    );
}
