import {useCallback, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {setDate} from "date-fns";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {getYearMonthDate, parseYearMonthDate} from "@fleet/common/utils/dateFormatUtils";

import {ListItem, OptionProps, Select, SelectOption} from "@bolteu/kalep-react";

import {usePeriodOptions} from "../hooks/usePeriodOptions";

interface Props {
    selectedDate: Date;
    onChange: (date: Date) => void;
}

const PeriodFilter = ({selectedDate, onChange}: Props) => {
    const {i18n} = useI18n();
    const createdUnix = useContext(AccountContextProvider)?.getFleet()?.created;
    const periodOptions = usePeriodOptions(createdUnix);

    const renderOption = useCallback(({option, ...props}: OptionProps) => {
        const fontStyle = option.disabled ? "font-semibold" : "";
        return <ListItem overrideClassName={`min-h-10 ${fontStyle}`} {...props} />;
    }, []);

    const onSelectChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            let newDateStr: string;
            if (Array.isArray(newValue)) {
                newDateStr = String(newValue[0].value);
            } else {
                newDateStr = String(newValue?.value);
            }
            const newDate = parseYearMonthDate(newDateStr, setDate(new Date(), 1));
            onChange(newDate);
        },
        [onChange],
    );

    const selectedValue = periodOptions.find((opt) => opt.value === getYearMonthDate(selectedDate));

    return (
        <div className="w-60 shrink-0">
            <Select
                value={selectedValue}
                onChange={onSelectChange}
                options={periodOptions}
                label={i18n("common.period")}
                fullWidth
                renderOption={renderOption}
                clearable={false}
            />
        </div>
    );
};

export default PeriodFilter;
