import {useEffect} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ConditionAndMileage from "./components/listing-view-tab/ConditionAndMileage";
import Details from "./components/listing-view-tab/Details";
import DriverRequirements from "./components/listing-view-tab/DriverRequirements";
import Photos from "./components/listing-view-tab/Photos";
import Pricing from "./components/listing-view-tab/Pricing";
import VehiclePicture from "./components/listing-view-tab/VehiclePicture";
import ListingActions from "./components/ListingActions";
import ViewListingInVMLink from "./components/ViewListingInVMLink";

import BodyType = VehicleMarketplaceService.CarModelBodyType;
import ListingData = VehicleMarketplaceService.ListingData;
import ListingFieldsTranslations = VehicleMarketplaceService.ListingFieldsTranslations;

const getListingImagesFunction = (api: Api, body: VehicleMarketplaceService.GetListingImagesRequest) =>
    api.vehicleMarketplace.vehicleListingGetImageUrls(body);

interface Props {
    listingId: number;
    listingData: ListingData;
    listingDataTranslations: ListingFieldsTranslations;
    refreshListingData: () => void;
}

const ListingViewTab = ({listingId, listingData, listingDataTranslations, refreshListingData}: Props) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const {data, fetch} = useFetch(getListingImagesFunction);

    useEffect(() => {
        if (fetch) {
            fetch({listingId});
        }
    }, [fetch, listingId]);

    if (isMobileView) {
        return (
            <div className="flex flex-col gap-6">
                <Details listingDataTranslations={listingDataTranslations} />
                <ConditionAndMileage listingDataTranslations={listingDataTranslations} />
                <Pricing listingData={listingData} listingDataTranslations={listingDataTranslations} />
                <Photos />
                <DriverRequirements listingDataTranslations={listingDataTranslations} />
                <ListingActions
                    listingId={Number(listingId)}
                    listingData={listingData}
                    refreshListingData={refreshListingData}
                />
            </div>
        );
    }

    return (
        <div className="grid h-full grid-cols-12 gap-4">
            <div className="col-span-4">
                <div className="bg-layer-surface flex h-full flex-col gap-6 overflow-y-auto rounded-lg px-6 py-4">
                    <VehiclePicture
                        vehicleImageUrl={data?.image_urls[0].thumb_400_url}
                        vehicleModelBodyType={listingData.model_body_type ?? BodyType.SEDAN}
                    />
                    <Pricing listingData={listingData} listingDataTranslations={listingDataTranslations} />
                    <ViewListingInVMLink listingId={Number(listingId)} city={listingData.city} />
                </div>
            </div>
            <div className="col-span-full col-start-6 flex flex-col gap-10">
                <Details listingDataTranslations={listingDataTranslations} />
                <ConditionAndMileage listingDataTranslations={listingDataTranslations} />
                <Photos />
                <DriverRequirements listingDataTranslations={listingDataTranslations} />
            </div>
        </div>
    );
};

export default ListingViewTab;
