import {FC, useCallback} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {getDateFormat} from "@fleet/common/utils/dateFormatUtils";

import {DatePicker} from "@bolteu/kalep-react";

interface ExpiryDatePickerProps {
    onChange: (date: Date | null) => void;
    date: Date | null;
}

const ExpiryDatePicker: FC<ExpiryDatePickerProps> = ({date, onChange}) => {
    const intl = useIntl();
    const {i18n} = useI18n();

    const onDateChange = useCallback(
        (newDate: Date | Date[] | null) => {
            if (!Array.isArray(newDate)) {
                onChange(newDate);
            }
        },
        [onChange],
    );

    return (
        <DatePicker
            label={i18n("upload_document.expiration_date")}
            value={date}
            onChange={onDateChange}
            format={getDateFormat(intl)}
            minDate={new Date()}
            locale={intl.locale}
            fullWidth
        />
    );
};

export default ExpiryDatePicker;
