import {useCallback, useContext, useEffect, useState} from "react";

import Pagination from "common/components/Pagination";
import LoadingSpinner from "common/components/spinner";
import TableWithCustomizableColumns from "common/components/table/TableWithCustomizableColumns";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

import {useTableColumns} from "../hooks/useOrderTableColumns";
import NoOrders from "./NoOrders";
import {OrderFilters} from "./OrdersFilter";

export const ORDER_HISTORY_TABLE_ID = "order-history-table";
interface Props {
    selectedPeriod: ApiPeriod;
    selectedDriver: SelectOption | null;
    filters: OrderFilters;
}

const MAX_PAGE_LIMIT = 25;

const getOrdersFetchFunction = (api: Api, body: FleetOwnerReportingService.GetOrdersV2SearchRequest) =>
    api.fleetOwnerReporting.getOrdersHistory(body);

const OrdersTable = ({selectedPeriod, selectedDriver, filters}: Props) => {
    const columns = useTableColumns();

    const fleet = useContext(AccountContextProvider)?.getFleet();

    const [pageNum, setPageNum] = useState(0);

    const {data, fetch, status} = useFetch(getOrdersFetchFunction);
    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(status);

    const getOrdersDashboardData = useCallback(
        async (newPageNum: number) => {
            if (fetch) {
                const dates =
                    selectedPeriod.period === Period.CUSTOM
                        ? {start: selectedPeriod.startDate, end: selectedPeriod.endDate}
                        : apiPeriodToDates(selectedPeriod);

                const offset = newPageNum * MAX_PAGE_LIMIT;
                const driverId = Number(selectedDriver?.value);
                fetch({
                    offset,
                    limit: MAX_PAGE_LIMIT,
                    from_date: getIsoDate(dates.start),
                    to_date: getIsoDate(dates.end),
                    driver_id: !Number.isNaN(driverId) && driverId > 0 ? driverId : undefined,
                    orders_state_statuses: filters.orders_state_statuses,
                    city_id: filters.city_id,
                    search_category_group: filters.search_category_group,
                });
            }
        },
        [fetch, selectedPeriod, selectedDriver, filters],
    );

    useEffect(() => {
        if (fleet) {
            setPageNum(0);
            getOrdersDashboardData(0);
        }
    }, [getOrdersDashboardData, fleet]);

    const onPageChange = useCallback(
        async (page: number) => {
            if (page === pageNum) {
                return;
            }

            getOrdersDashboardData(page);
            setPageNum(page);
        },
        [getOrdersDashboardData, pageNum],
    );

    const renderEmptyPlaceholder = useCallback(() => {
        return isLoading ? <LoadingSpinner show parentClassName="mt-2" /> : <NoOrders />;
    }, [isLoading]);

    const items = data?.rows.map((x) => ({...x, id: x.order_id})) || [];

    return (
        <>
            <TableWithCustomizableColumns
                columns={columns}
                items={items}
                aria-label="Orders table"
                renderEmptyPlaceholder={renderEmptyPlaceholder}
                id={ORDER_HISTORY_TABLE_ID}
                isLoading={isLoading}
            />
            <Pagination
                currentPage={pageNum}
                pageSize={MAX_PAGE_LIMIT}
                totalCount={data?.total_rows || 0}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </>
    );
};

export default OrdersTable;
