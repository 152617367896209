import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {getDayMonthYearDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {getDecimalSeparatorFromLocale} from "../../../../../common/services/decimalSeparator";
import {OrderTryFinalStates} from "../components/OrdersFilter";

export function useOrderHistoryDownloadApi(
    period: ApiPeriod,
    ordersStateStatuses: OrderTryFinalStates[],
    driverId?: number,
): (api: Api) => Promise<FleetOwnerReportingService.GetOrdersCsvResponse> {
    const dates =
        period.period === Period.CUSTOM ? {start: period.startDate, end: period.endDate} : apiPeriodToDates(period);

    const fetchOrderHistory = (api: Api) =>
        api.fleetOwnerReporting.getOrdersCsvV2({
            start_date: getDayMonthYearDate(dates.start),
            end_date: getDayMonthYearDate(dates.end),
            decimal_separator: getDecimalSeparatorFromLocale(),
            orders_state_statuses: convertOrderTryFinalStatesToGetOrdersCsvOrderStates(ordersStateStatuses),
            driver_id: driverId,
        });

    return fetchOrderHistory;
}

function convertOrderTryFinalStatesToGetOrdersCsvOrderStates(
    ordersStateStatuses: OrderTryFinalStates[],
): FleetOwnerReportingService.GetOrdersCsvOrderStates[] {
    return ordersStateStatuses.map(orderTryFinalStateToGetOrdersCsvOrderState);
}

function orderTryFinalStateToGetOrdersCsvOrderState(
    orderTryFinalState: OrderTryFinalStates,
): FleetOwnerReportingService.GetOrdersCsvOrderStates {
    switch (orderTryFinalState) {
        case FleetOwnerReportingService.OrderTryOfferState.CLIENT_CANCELLED:
            return FleetOwnerReportingService.GetOrdersCsvOrderStates.CLIENT_CANCELLED;
        case FleetOwnerReportingService.OrderTryOfferState.DRIVER_DID_NOT_RESPOND:
            return FleetOwnerReportingService.GetOrdersCsvOrderStates.DRIVER_DID_NOT_RESPOND;
        case FleetOwnerReportingService.OrderTryOfferState.DRIVER_REJECTED:
            return FleetOwnerReportingService.GetOrdersCsvOrderStates.DRIVER_REJECTED;
        case FleetOwnerReportingService.OrderTryOfferState.CLIENT_DID_NOT_SHOW:
            return FleetOwnerReportingService.GetOrdersCsvOrderStates.CLIENT_DID_NOT_SHOW;
        case FleetOwnerReportingService.OrderTryOfferState.FINISHED:
            return FleetOwnerReportingService.GetOrdersCsvOrderStates.FINISHED;
        case FleetOwnerReportingService.OrderTryStateCustom.DRIVER_CANCELLED_AFTER_ACCEPT:
            return FleetOwnerReportingService.GetOrdersCsvOrderStates.DRIVER_CANCELLED_AFTER_ACCEPT;

        default:
            throw new Error(`Unknown order try final state: ${orderTryFinalState}`);
    }
}
