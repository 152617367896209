import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import LockAlertIcon from "assets/icons/lock-alert.svg?react";
import {CustomerSupportContact} from "common/components/customerSupport/CustomerSupportContact";
import {FleetRoute} from "common/constants/routes";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {TranslationKeys} from "config/translations";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetOwnerPortalService, FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {BaseModal, Button, IconButton, Typography} from "@bolteu/kalep-react";
import {Cross} from "@bolteu/kalep-react-icons";
import CompanyStatus = FleetOwnerService.ExpandedCompanyStatus;
import BlockResolutionAction = FleetOwnerPortalService.BlockResolutionAction;
import CompanyBlock = FleetOwnerPortalService.CompanyBlock;

export const getCompanyActiveBlocksFetch = (api: Api) => api.fleetOwnerPortal.getCompanyActiveBlocks();
const getCompanyDetailsFetchFunction = (api: Api) => api.fleetOwnerPortal.getCompanyDetails();

const filterActiveBlocks = (blocks: FleetOwnerPortalService.CompanyBlock[]) => {
    const now = new Date().getTime();
    return Array.from(
        new Set(blocks.filter((block) => block.start * 1000 < now && (!block.end || block.end * 1000 > now))),
    );
};

const getActionButtonProps = (action?: BlockResolutionAction): {label: TranslationKeys; toPath: FleetRoute} => {
    switch (action) {
        case BlockResolutionAction.BALANCE:
            return {
                label: "auth.app.fleet.balance.view_balance",
                toPath: FleetRoute.BALANCE,
            };
        case BlockResolutionAction.TAX_INFO:
            return {
                label: "auth.app.company-settings.tax_info.update_tax_info",
                toPath: FleetRoute.COMPANY_SETTINGS_TAX,
            };
        case BlockResolutionAction.DOCUMENTS:
            return {
                label: "documents.update_documents",
                toPath: FleetRoute.COMPANY_SETTINGS,
            };
        default:
            return {
                label: "company-selector.open_settings",
                toPath: FleetRoute.COMPANY_SETTINGS,
            };
    }
};

const Header = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    return (
        <>
            <LockAlertIcon className="mb-9 self-center" />
            <div className="mt-6 mb-4">
                <Typography variant="heading-s-accent" align="center">
                    {i18n("company.blocks.access_to_your_named_company_is_restricted", {
                        company_name: fleet?.company_name ?? "",
                    })}
                </Typography>
            </div>
        </>
    );
};

const Body = ({
    activeBlocks,
    close,
    csEmail,
}: {
    activeBlocks: CompanyBlock[];
    close: () => void;
    csEmail?: string | null;
}) => {
    const {i18n} = useI18n();
    const uniqueDescriptions = new Set(activeBlocks.map((block) => block.description));
    return (
        <div>
            <ul className={uniqueDescriptions.size > 1 ? "ml-4 list-outside list-disc" : ""}>
                {Array.from(uniqueDescriptions).map((desc) => (
                    <li key={desc}>
                        <Typography variant="body-m-regular" inline>
                            {desc}
                        </Typography>
                    </li>
                ))}
            </ul>
            <div>
                <Typography variant="body-m-regular">
                    {i18n("company.blocks.access_restriction_applies_to_everything")}
                </Typography>
            </div>
            <div>
                <CustomerSupportContact
                    customerSupportEmail={csEmail}
                    csWidgetKey="common.if_issues_contact_customer_support"
                    csWidgetLinkPlaceholder="customer_support"
                    emailKey="common.if_issues_contact_customer_support"
                    emailLinkPlaceholder="customer_support"
                    fallbackKey="common.if_issues_contact_customer_support"
                    textVariant="body-m-regular"
                    textColor="primary"
                    onCsClick={close}
                />
            </div>
        </div>
    );
};

const Footer = ({onClick, label}: {onClick: () => void; label: string}) => {
    return (
        <BaseModal.Footer>
            <div className="flex flex-row justify-center">
                <Button onClick={onClick}>{label}</Button>
            </div>
        </BaseModal.Footer>
    );
};

export const BlockNotificationDialog = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";
    const [isOpen, setIsOpen] = useState(false);

    const {data: blocks, fetch: fetchBlocks} = useFetch(getCompanyActiveBlocksFetch);
    const {data: companyData, fetch: fetchCompanyData} = useFetch(getCompanyDetailsFetchFunction);

    const close = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    useEffect(() => {
        if (fetchBlocks && fleet?.status === CompanyStatus.BLOCKED) {
            fetchBlocks({});
        }
    }, [fetchBlocks, fleet?.status]);

    useEffect(() => {
        if (fetchCompanyData && fleet?.status === CompanyStatus.BLOCKED) {
            fetchCompanyData({});
        }
    }, [fetchCompanyData, fleet?.status]);

    useEffect(() => {
        if (blocks?.blocks.length && filterActiveBlocks(blocks.blocks).length) {
            setIsOpen(true);
        }
    }, [blocks]);

    const activeBlocks = filterActiveBlocks(blocks?.blocks ?? []);
    const action = activeBlocks.find((block) => block.resolution_action !== BlockResolutionAction.NONE);
    const actionButtonProps = getActionButtonProps(action?.resolution_action);

    const actionButtonOnClick = useCallback(() => {
        if (!actionButtonProps) {
            return;
        }
        navigate(actionButtonProps.toPath);
        setIsOpen(false);
    }, [actionButtonProps, navigate]);

    if (!activeBlocks.length) {
        return null;
    }

    const parentClassNames = isMobileView ? "w-dvh h-dvh" : "max-w-md rounded-lg";
    return (
        <BaseModal isOpen={isOpen} onRequestClose={close} placement={isMobileView ? "m-0" : ""}>
            <div className={`bg-layer-floor-1 ${parentClassNames}`}>
                <div className="flex flex-col">
                    <div className="mt-4 mr-4 self-end">
                        <IconButton icon={<Cross />} size="sm" onClick={close} aria-label="Close" autoFocus />
                    </div>
                    <BaseModal.Content>
                        <div className="flex flex-col gap-2">
                            <Header />
                            <Body
                                activeBlocks={activeBlocks}
                                close={close}
                                csEmail={companyData?.customer_support_email}
                            />
                        </div>
                    </BaseModal.Content>
                    <Footer onClick={actionButtonOnClick} label={i18n(actionButtonProps.label)} />
                </div>
            </div>
        </BaseModal>
    );
};
