import {useCallback, useEffect, useState} from "react";

import {FetchStatus} from "common/hooks/useFetch";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

interface Props {
    fetchStatus: FetchStatus;
    fetchData: FleetPortalOrderService.GetDriversForAssignmentResponse | null;
    selectedDriverName?: string;
    onChange: (driverId?: number, driverName?: string) => void;
}

export const useAssigmentDrivers = ({fetchData, fetchStatus, selectedDriverName, onChange}: Props) => {
    const [searchValue, setSearchValue] = useState("");
    const [driversOptions, setDriversOptions] = useState<SelectOption[] | null>(null);

    useEffect(() => {
        if (fetchStatus === FetchStatus.Success && fetchData) {
            const drivers = [...fetchData.compatible_drivers, ...fetchData.incompatible_drivers].sort((a, b) =>
                a.name.localeCompare(b.name),
            );
            setDriversOptions(
                drivers.map((driver) => ({
                    title: driver.name,
                    value: driver.id,
                    secondary: driver.incompatibility_reason,
                    disabled: Boolean(driver.incompatibility_reason),
                })),
            );
        }
    }, [fetchData, fetchStatus]);

    useEffect(() => {
        if (selectedDriverName) {
            setSearchValue(selectedDriverName);
        }
    }, [selectedDriverName, setSearchValue]);

    const onDriverChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            let driverId: number | undefined;
            if (newValue === null) {
                driverId = undefined;
            } else {
                const newDriver = Array.isArray(newValue) ? newValue[0] : newValue;
                driverId = newDriver.value as number;
            }
            const driverName = driverId
                ? driversOptions?.find((driver) => driver.value === driverId)?.title
                : undefined;
            onChange(driverId, driverName as string | undefined);
        },
        [driversOptions, onChange],
    );

    return {onDriverChange, driversOptions, searchValue, setSearchValue};
};
