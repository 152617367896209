import {FC, useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {Button, Dialog} from "@bolteu/kalep-react";

export interface ConfirmDeletionDialogProps {
    show: boolean;
    closeModal: () => void;
    confirmDelete: () => Promise<void>;
}

const ConfirmDeletionDialog: FC<ConfirmDeletionDialogProps> = ({show, closeModal, confirmDelete}) => {
    const {i18n} = useI18n();
    const [isLoading, setIsLoading] = useState(false);

    const onConfirmDelete = useCallback(async () => {
        setIsLoading(true);
        try {
            await confirmDelete();
        } finally {
            setIsLoading(false);
        }
    }, [confirmDelete]);

    return (
        <ResponsiveDialog
            title={i18n("company_registration.delete_application.dialog.title")}
            isOpen={show}
            onRequestClose={closeModal}
        >
            <Dialog.Content>
                <div className="text-base text-neutral-700">
                    {i18n("company_registration.delete_application.dialog.body")}
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={closeModal} disabled={isLoading}>
                        {i18n("common.cancel")}
                    </Button>
                    <Button variant="danger" onClick={onConfirmDelete} loading={isLoading}>
                        {i18n("common.delete")}
                    </Button>
                </div>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};

export default ConfirmDeletionDialog;
