import {FC, useCallback, useMemo, useState} from "react";

import NoResults from "common/components/NoResults";
import Pagination from "common/components/Pagination";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import LoadingSpinner from "common/components/spinner";
import ApiDrivenTable from "common/components/table/ApiDrivenTable";
import ApiDrivenTablesCustomizationDrawer from "common/components/table/ApiDrivenTable/ApiDrivenTableCustomizationDrawer";
import {AlignVertical, ApiDrivenTableTransforms, CellProps} from "common/components/table/ApiDrivenTable/types";
import {Tier} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {selectPeriodOptionsUntilNow} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";
import TableSearchField from "@fleet/common/components/table/TableSearchField";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";

import {useDriverRewardsIcons} from "../../profile/hooks/useDriverRewardsIcons";
import {AddDriver} from "./AddDriver";
import DriversDownload from "./DriversDownload";
import DriversFilter, {DriverFilter} from "./DriversFilter";
import FilteredBy from "./DriversFilteredBy";
import ApiColumn = FleetOwnerPortalService.ApiColumn;
import TierStatus = FleetOwnerPortalService.TierStatus;

interface DriversTabProps {
    columns?: ApiColumn[];
    selectedPeriod: ApiPeriod;
    onPeriodChange: (period: ApiPeriod) => void;
    onSearchChange: (search: string) => void;
    driverCount?: number;
    pageSize: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    isLoading: boolean;
    filters: DriverFilter;
    setFilters: (filters: DriverFilter) => void;
}

export const DRIVERS_TABLE_ID = "driver-list-table";

const DriversTab: FC<DriversTabProps> = ({
    columns,
    selectedPeriod,
    onPeriodChange,
    currentPage,
    driverCount,
    pageSize,
    onPageChange,
    onSearchChange,
    isLoading,
    filters,
    setFilters,
}) => {
    const {i18n} = useI18n();
    const [isInvalidPeriod, setIsInvalidPeriod] = useState<boolean>(false);

    const onPeriodErrorStateChange = useCallback((hasError: boolean) => {
        setIsInvalidPeriod(hasError);
    }, []);

    const transforms = useMemo((): ApiDrivenTableTransforms => {
        const TierIconRenderer: FC<{tier: Tier; tierStatus: TierStatus}> = ({tier, tierStatus}) => {
            return <div className="tier-tooltip">{useDriverRewardsIcons(tier, tierStatus)}</div>;
        };

        const getCellValue = (props: CellProps) => props.column.cells[props.valueAtIdx];

        const isTierData = (value: unknown): value is {tier: Tier; tier_status: TierStatus; tier_name?: string} => {
            return typeof value === "object" && value !== null && "tier" in value && "tier_status" in value;
        };

        const getStatusText = (tierStatus: TierStatus) => {
            return tierStatus === TierStatus.INACTVIE ? `(${i18n("common.inactive")})` : "";
        };

        const renderCellOnDesktop = (props: CellProps) => {
            const cellValue = getCellValue(props);
            if (isTierData(cellValue)) {
                const statusText = getStatusText(cellValue.tier_status);
                return (
                    <Tooltip
                        content={`${i18n("auth.app.fleet.driver.tier_tooltip.title")}: ${
                            cellValue.tier_name
                        } ${statusText}`}
                        placement="bottom"
                    >
                        <div>
                            <TierIconRenderer tier={cellValue.tier} tierStatus={cellValue.tier_status} />
                        </div>
                    </Tooltip>
                );
            }
            return <></>;
        };

        const renderCellOnMobile = (props: CellProps) => {
            const cellValue = getCellValue(props);
            if (isTierData(cellValue)) {
                const statusText = getStatusText(cellValue.tier_status);
                return (
                    <>
                        {cellValue.tier_name} {statusText}
                    </>
                );
            }
            return <></>;
        };

        return {
            tier: {
                renderCellOnMobile,
                renderCellOnDesktop,
                renderCellInAccordionTitle: renderCellOnDesktop,
            },
        };
    }, [i18n]);

    let driversTable;
    if (isLoading) {
        driversTable = <LoadingSpinner show parentClassName="mt-2" />;
    } else if (columns && driverCount && driverCount > 0) {
        driversTable = (
            <ApiDrivenTable
                tableId={DRIVERS_TABLE_ID}
                columns={columns}
                alignVertical={AlignVertical.MIDDLE}
                numberOfRecords={columns[0].cells.length ?? 0}
                transforms={transforms}
            />
        );
    } else {
        driversTable = (
            <NoResults
                titleKey="auth.app.fleet.drivers.no-drivers-yet"
                subTitleKey="auth.app.fleet.drivers.driver-add"
                showActionComponent
                actionComponent={<AddDriver />}
            />
        );
    }

    return (
        <>
            <div className="flex flex-col gap-4 lg:flex-row lg:justify-between">
                <div className="flex flex-[2] justify-between gap-2 sm:justify-start sm:gap-4">
                    <TableSearchField
                        onChange={onSearchChange}
                        placeholder={i18n("auth.app.fleet.reports.search_by_name")}
                    />
                    <DriversFilter filters={filters} setFilters={setFilters} />
                    <ApiDrivenTablesCustomizationDrawer tableId={DRIVERS_TABLE_ID} columns={columns ?? []} />
                </div>
                <div className="flex flex-col gap-4 sm:flex-row">
                    <div className="w-full sm:w-auto">
                        <PeriodDatePicker
                            period={selectedPeriod}
                            onPeriodChange={onPeriodChange}
                            selectPeriodOptions={selectPeriodOptionsUntilNow}
                            dateRangeLimitDays={31}
                            onErrorStateChange={onPeriodErrorStateChange}
                        />
                    </div>
                    <DriversDownload period={selectedPeriod} isError={isInvalidPeriod} />
                </div>
            </div>
            <FilteredBy filters={filters} setFilters={setFilters} />
            {driversTable}
            <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={driverCount}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </>
    );
};

export default DriversTab;
