import * as React from "react";
import {useContext, useEffect, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {addDays} from "date-fns";
import TableSearchField from "@fleet/common/components/table/TableSearchField";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetPortalDocumentService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton} from "@bolteu/kalep-react";
import {Filters} from "@bolteu/kalep-react-icons";

import {AccountContextProvider} from "../../account/accountStateProvider";
import {ExpiringDocumentsFilters, ExpiringDocumentsFiltersDrawer} from "./ExpiringDocumentsFiltersDrawer";
import {useTableColumns} from "./hooks/useTableColumns";

const DEFAULT_EXPIRATION_PERIOD_END_OFFSET = 60;

export interface ExpiringDocumentTableItem extends FleetPortalDocumentService.ExpiringDocument {
    identifier: string;
}
const fetchFunction = async (api: Api, body: ExpiringDocumentsFilters & {fleetName: string}) => {
    const response = await api.fleetPortalDocument.v2GetExpiringDocuments({
        entityTypes: body.entityTypes.length > 0 ? body.entityTypes : undefined,
        expirationPeriod: {
            start: body.expirationPeriodStart ?? undefined,
            end: body.expirationPeriodEnd,
        },
    });

    return response.documents.map((doc) => ({
        ...doc,
        identifier:
            doc.entity_type === FleetPortalDocumentService.OwnerType.COMPANY ? body.fleetName : doc.identifier || "",
    }));
};

interface ExpiringDocumentDocumentsItemsProps {
    searchQuery: string;
    filters: ExpiringDocumentsFilters;
}

const ExpiringDocumentDocumentsItems = ({searchQuery, filters}: ExpiringDocumentDocumentsItemsProps) => {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const fleetId = fleet?.id;
    const fleetName = fleet?.company_name ?? fleet?.name ?? "";

    const {data: items, fetch, status} = useFetch(fetchFunction);
    useEffect(() => {
        if (fleetId && fetch) {
            fetch({...filters, fleetName});
        }
    }, [fetch, filters, fleetId, fleetName]);

    const columns = useTableColumns();

    const [filteredItems, setFilteredItems] = useState<ExpiringDocumentTableItem[]>([]);

    useEffect(() => {
        if (searchQuery) {
            const lowerCaseSearchQuery = searchQuery.toLowerCase();
            setFilteredItems(
                items?.filter(
                    (item) =>
                        item.type_title.toLowerCase().includes(lowerCaseSearchQuery) ||
                        item.identifier.toLowerCase().includes(lowerCaseSearchQuery),
                ) || [],
            );
        } else {
            setFilteredItems(items || []);
        }
    }, [items, searchQuery]);

    if (status === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    return (
        <FixedHeaderTable
            columns={columns}
            items={filteredItems}
            aria-label="Expiring documents table"
            isLoading={[FetchStatus.Init, FetchStatus.Loading].includes(status)}
        />
    );
};

export const ExpiringDocumentsTable = () => {
    const {i18n} = useI18n();
    const [searchQuery, setSearchQuery] = React.useState("");

    const defaultFilters: ExpiringDocumentsFilters = {
        expirationPeriodStart: null,
        expirationPeriodEnd: getDefaultExpirationPeriodEnd(),
        entityTypes: [
            FleetPortalDocumentService.OwnerType.DRIVER,
            FleetPortalDocumentService.OwnerType.CAR,
            FleetPortalDocumentService.OwnerType.COMPANY,
        ],
    };
    const [filters, setFilters] = React.useState(defaultFilters);

    const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = React.useState(false);

    const handleSearch = React.useCallback(
        (query: string) => {
            setSearchQuery(query);
        },
        [setSearchQuery],
    );

    const handleFilersChanged = React.useCallback(
        (newFilters: ExpiringDocumentsFilters) => {
            setFilters(newFilters);
            setIsFiltersDrawerOpen(false);
        },
        [setFilters],
    );

    const handleFilersDrawerClosed = React.useCallback(() => {
        setIsFiltersDrawerOpen(false);
    }, [setIsFiltersDrawerOpen]);

    const handleFiltersButtonClicked = React.useCallback(() => {
        setIsFiltersDrawerOpen(true);
    }, [setIsFiltersDrawerOpen]);

    return (
        <>
            <div className="flex gap-4">
                <TableSearchField
                    onChange={handleSearch}
                    placeholder={i18n("auth.app.fleet.expiring-documents.search")}
                />
                <IconButton
                    icon={<Filters />}
                    onClick={handleFiltersButtonClicked}
                    aria-label="Filter expiring documents"
                    variant="filled"
                    size="md"
                    shape="square"
                    overrideClassName="flex"
                />
            </div>
            <ExpiringDocumentDocumentsItems searchQuery={searchQuery} filters={filters} />
            <ExpiringDocumentsFiltersDrawer
                isOpen={isFiltersDrawerOpen}
                filters={filters}
                defaultFilters={defaultFilters}
                onFiltersChanged={handleFilersChanged}
                onClosed={handleFilersDrawerClosed}
            />
        </>
    );
};

function getDefaultExpirationPeriodEnd() {
    return getIsoDate(addDays(new Date(), DEFAULT_EXPIRATION_PERIOD_END_OFFSET));
}
