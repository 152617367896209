import {FC, useCallback, useContext, useEffect, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import Header, {HeaderSize} from "common/components/header/Header";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import ApiDrivenStatCard from "common/components/table/ApiDrivenMetricCard";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {SelectPeriodOption} from "common/hooks/usePeriodSelectorOptions";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, OngoingDay, Period} from "common/services/period";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import PeriodSelect from "../../../common/components/periodDatePicker/PeriodSelect";
import {FeaturesContextProvider} from "../../../FeaturesProvider";
import DashboardCharts from "./components/DashboardCharts";
import DashboardTables from "./components/DashboardTables";
import {MetricsTab, useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

import GetCompanyPerformanceMetricsRequest = FleetOwnerPortalService.GetCompanyPerformanceMetricsRequest;

const getCompanyPerformanceFetchFunction = (api: Api, query: GetCompanyPerformanceMetricsRequest) =>
    api.fleetOwnerPortal.getCompanyPerformance(query);

const DashboardPage: FC = () => {
    const {i18n} = useI18n();
    const tabs = useTabSelectorOptions();
    const features = useContext(FeaturesContextProvider);

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.PREVIOUS_7_DAYS});

    const {activeTab, TabSelector} = useTabs(tabs, MetricsTab.Drivers);

    const {fetch: getCompanyPerformanceFetch, data: getCompanyPerformanceData} = useFetch(
        getCompanyPerformanceFetchFunction,
    );

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.dashboard.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (getCompanyPerformanceFetch) {
            getCompanyPerformanceFetch({
                cutoff_date: getIsoDate(apiPeriodToDates({period: Period.ONGOING_DAY} as OngoingDay).end),
            });
        }
    }, [getCompanyPerformanceFetch]);

    const onPeriodChange = useCallback(
        (apiPeriod: ApiPeriod | undefined) => {
            if (apiPeriod && getCompanyPerformanceFetch) {
                setSelectedPeriod(apiPeriod);
                getCompanyPerformanceFetch({
                    cutoff_date: getIsoDate(apiPeriodToDates(apiPeriod).end),
                });
            }
        },
        [getCompanyPerformanceFetch],
    );

    if (!features?.company_metrics_enabled) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    if (!getCompanyPerformanceData) {
        return null;
    }

    let MetricsTables = null;
    switch (activeTab) {
        case MetricsTab.Vehicles:
            MetricsTables = (
                <DashboardTables
                    topEntities={getCompanyPerformanceData?.tables.top_vehicles}
                    bottomEntities={getCompanyPerformanceData?.tables.bottom_vehicles}
                />
            );
            break;
        case MetricsTab.Drivers:
        default:
            MetricsTables = (
                <DashboardTables
                    topEntities={getCompanyPerformanceData?.tables.top_drivers}
                    bottomEntities={getCompanyPerformanceData?.tables.bottom_drivers}
                />
            );
    }

    return (
        <Page>
            <div className="flex flex-row flex-wrap items-baseline justify-between">
                <div className="flex flex-col gap-4">
                    <ResponsiveHeader
                        type={ResponsiveHeaderType.MainPage}
                        text={i18n("auth.app.fleet.dashboard.title")}
                    />
                    <div className="font-normal text-neutral-700">{i18n("auth.app.fleet.dashboard.description")}</div>
                </div>
                <div className="flex-col">
                    <PeriodSelect
                        period={selectedPeriod}
                        selectPeriodOptions={[
                            SelectPeriodOption.Previous7Days,
                            SelectPeriodOption.ThisWeek,
                            SelectPeriodOption.LastWeek,
                        ]}
                        onSelectionChange={onPeriodChange}
                        excludeCustomRange
                    />
                </div>
            </div>
            <div className="flex flex-nowrap gap-4 overflow-x-auto">
                {getCompanyPerformanceData.metrics.map((metric) => (
                    <ApiDrivenStatCard metric={metric} />
                ))}
            </div>
            <DashboardCharts charts={getCompanyPerformanceData?.charts} />
            <div className="mt-4 flex flex-col gap-2">
                <Header size={HeaderSize.Small} text={i18n("auth.app.fleet.dashboard.weekly_performers.title")} />
                <div className="font-normal text-neutral-700">
                    {i18n("auth.app.fleet.dashboard.weekly_performers.description")}
                </div>
            </div>
            <TabSelector />
            {MetricsTables}
        </Page>
    );
};

export default DashboardPage;
