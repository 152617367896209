import {useCallback, useEffect, useState} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ComboboxText} from "@fleet/common/components/dynamic_form/ComboboxText";
import {AdditionalFields, FormValueType} from "@fleet/common/components/dynamic_form/types";
import {
    getApiToFormValues,
    getErrorMessage,
    getFilteredAndSortedFields,
    getFormField,
    getFormToApiValues,
} from "@fleet/common/components/dynamic_form/util";
import {ValidatorResult} from "@fleet/common/hooks/useFetch";

import {FleetOwnerRegistrationService, VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormChange, FormState, useForm} from "@bolteu/kalep-form-react";
import {Button, Typography} from "@bolteu/kalep-react";

import WizardFileUpload from "../../common/components/WizardFileUpload";
import ListingStep = VehicleMarketplaceService.ListingStep;
import FleetFeedback from "./FleetFeedback";

export const vehicleListingUpdateSubmitFunction = (api: Api, body: VehicleMarketplaceService.UpdateListingRequest) =>
    api.vehicleMarketplace.vehicleListingUpdate(body);

export const vehicleListingValidateFunction = (api: Api, body: VehicleMarketplaceService.ValidateListingRequest) =>
    api.vehicleMarketplace.vehicleListingValidateForm(body);

interface Props {
    step: ListingStep;
    listingId: number;
    fleetSupportLink?: string;
    onNextStep: () => void;
    onPrevStep: () => void;
}

const shouldValidateFormChangeEvent = (
    formData: FormChange<FormValueType>,
    formFields: FleetOwnerRegistrationService.FormField[],
): boolean => {
    const {event} = formData;

    const textFieldNames = formFields
        .filter(({type}) =>
            [
                FleetOwnerRegistrationService.FormFieldType.RADIOGROUP,
                FleetOwnerRegistrationService.FormFieldType.COMBOBOX,
            ].includes(type),
        )
        .map(({name}) => name);

    const textFieldNamesSet = new Set(textFieldNames);
    return textFieldNamesSet.has(event.name);
};

const WizardContentHeader = ({title, subtitle}: {title: string; subtitle?: string}) => {
    return (
        <div className="flex flex-col gap-6">
            <Typography variant="title-primary" fontSize="text-3xl">
                {title}
            </Typography>
            {subtitle && <Typography variant="body-primary">{subtitle}</Typography>}
        </div>
    );
};

const WizardContent = ({step, listingId, fleetSupportLink, onNextStep, onPrevStep}: Props) => {
    const {i18n} = useI18n();
    const {fields} = step;

    const {fetch: submit, status: submitStatus, error: submitError} = useFetch(vehicleListingUpdateSubmitFunction);
    const {fetch: validate, data: validateData} = useFetch(vehicleListingValidateFunction);

    const [formFields, setFormFields] = useState<FleetOwnerRegistrationService.FormField[]>(fields);
    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);

    const [formValue, setFormValue] = useState<FormValueType>(getApiToFormValues(formFields));
    const useFormValue = useForm<FormValueType, AdditionalFields>(formValue, {
        ComboboxTextField: ComboboxText,
    });

    const {Form, Button: FormButton, setData} = useFormValue;

    useEffect(() => {
        if (submitError.validationErrors) {
            setValidatorResults(submitError.validationErrors);
        }
    }, [submitError.validationErrors]);

    useEffect(() => {
        if (submitStatus === FetchStatus.Success) {
            onNextStep();
        }
    }, [onNextStep, submitStatus]);

    const validateRequest = useCallback(
        async (request: VehicleMarketplaceService.UpdateListingData) => {
            if (!validate) {
                return;
            }
            try {
                await validate({listing_id: listingId, step_id: step.id, data: request});
            } catch (e) {
                // Ignore all errors
                // console.error(e);
            }
        },
        [validate, listingId, step.id],
    );

    useEffect(() => {
        if (!validateData) {
            return;
        }

        const {form_field_removed, form_field_added} = validateData;
        setFormFields((previousFormFields) => [
            ...previousFormFields.filter(
                (field) =>
                    !(form_field_added || []).some((f) => f.name === field.name) &&
                    !(form_field_removed || []).some((f) => f === field.name),
            ),
            ...(form_field_added || []),
        ]);
        const updatedFields = getApiToFormValues(form_field_added || []);
        setData(updatedFields);
    }, [setData, validateData]);

    const onSubmitForm = useCallback(
        async (state: FormState<FormValueType>) => {
            if (!submit) {
                return;
            }
            const {data} = state;
            const request: VehicleMarketplaceService.UpdateListingRequest = {
                data: getFormToApiValues<VehicleMarketplaceService.UpdateListingData>(data, formFields),
                listing_id: listingId,
                step_id: step.id,
                should_validate_required: true,
            };

            await submit(request);
        },
        [formFields, submit, listingId, step.id],
    );

    const onChange = useCallback(
        async (formData: FormChange<FormValueType>) => {
            const {data} = formData;
            setFormValue(data);

            if (!shouldValidateFormChangeEvent(formData, formFields)) {
                return;
            }

            const request = getFormToApiValues<VehicleMarketplaceService.UpdateListingData>(data, formFields);
            await validateRequest(request);
        },
        [formFields, validateRequest],
    );

    const getField = (field: FleetOwnerRegistrationService.FormField) => {
        const {type} = field;
        if (type === FleetOwnerRegistrationService.FormFieldType.IMAGEUPLOAD) {
            return <WizardFileUpload validationErrorMessage={getErrorMessage(field, validatorResults)} />;
        }
        return <div className="w-3/4">{getFormField(field, validatorResults, useFormValue)}</div>;
    };

    const onStepClick = useCallback(async () => {
        if (!submit) {
            return;
        }

        const request: VehicleMarketplaceService.UpdateListingRequest = {
            data: getFormToApiValues<VehicleMarketplaceService.UpdateListingData>(formValue, formFields),
            listing_id: listingId,
            step_id: step.id,
            should_validate_required: false,
        };

        await submit(request);

        onPrevStep();
    }, [onPrevStep, submit, formValue, formFields, listingId, step.id]);

    const isLoading = submitStatus === FetchStatus.Loading;

    return (
        <div className="w-full">
            <Form validatorResults={validatorResults} onSubmit={onSubmitForm} onChange={onChange}>
                <div className="mt-6 flex max-w-full flex-col gap-6">
                    {getFilteredAndSortedFields(formFields).map((field) => (
                        <div key={field.name} className="w-full">
                            {getField(field)}
                        </div>
                    ))}
                </div>
                <FleetFeedback fleetSupportLink={fleetSupportLink} />
                <div className="mt-8 flex justify-between">
                    {step.position > 1 && (
                        <Button variant="secondary" onClick={onStepClick} disabled={isLoading}>
                            {i18n("common.back")}
                        </Button>
                    )}
                    <FormButton submit loading={isLoading}>
                        {i18n("form.button.next")}
                    </FormButton>
                </div>
            </Form>
        </div>
    );
};

export default WizardContent;
export {WizardContentHeader};
