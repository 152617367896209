import {useIntl} from "react-intl";

import {formatCurrencyValue} from "@fleet/common/utils/currencyFormatUtils";

interface TransactionCardLegendSectionProps {
    name: string;
    value: number;
    colorClass: string;
    isMobileView: boolean;
    currencySymbol: string;
}

const TransactionCardLegendSection: React.FC<TransactionCardLegendSectionProps> = ({
    name,
    value,
    colorClass,
    isMobileView,
    currencySymbol,
}) => {
    const intl = useIntl();

    const formattedValue = formatCurrencyValue(intl, value);
    const legendText = isMobileView ? `${name}: ${formattedValue}\xa0${currencySymbol}` : name;

    return (
        <div className="flex items-center gap-2">
            <div className={`${colorClass} h-4 w-4 rounded-sm`} />
            <span className="text-xs">{legendText}</span>
        </div>
    );
};

export default TransactionCardLegendSection;
