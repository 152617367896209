import {ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState} from "react";

import LoadingSpinner from "common/components/spinner";
import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Dialog, Radio, RadioGroup} from "@bolteu/kalep-react";

const rejectReasonsFetchFunction = (api: Api) => api.leadToFleet.leadmatcherRejectReasons();

const rejectLeadFetchFunction = (api: Api, body: LeadToFleetService.RejectLeadRequest) =>
    api.leadToFleet.leadmatcherRejectLead(body);

export interface RejectDialogProps {
    show: boolean;
    closeModal: () => void;
    removeMatch: (props: {accepted: false}) => void;
    matcherLeadId?: number;
}

const RejectDialog: FC<RejectDialogProps> = ({show, closeModal, removeMatch, matcherLeadId}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const {
        data: rejectReasonsData,
        fetch: rejectReasonsFetch,
        status: rejectReasonsStatus,
    } = useFetch(rejectReasonsFetchFunction);
    const {
        fetch: rejectLeadFetch,
        status: rejectLeadStatus,
        error: rejectLeadError,
    } = useFetch(rejectLeadFetchFunction);

    const [selectedReason, setSelectedReason] = useState<string | null>(null);
    const [rejectReasonId, setRejectReasonId] = useState<number | null>(null);

    const isRejectReasonsLoading = rejectReasonsStatus === FetchStatus.Loading;

    const getRejectionReasons = useCallback(async () => {
        if (rejectReasonsFetch && show) {
            rejectReasonsFetch({});
        }
    }, [rejectReasonsFetch, show]);

    useEffect(() => {
        getRejectionReasons();
    }, [getRejectionReasons]);

    const rejectLead = useCallback(
        async (reasonId: number) => {
            if (matcherLeadId && rejectLeadFetch) {
                setRejectReasonId(reasonId);
                rejectLeadFetch({
                    matcher_lead_id: matcherLeadId,
                    reject_reason_id: reasonId,
                });
                setSelectedReason(null);
            }
        },
        [matcherLeadId, rejectLeadFetch],
    );

    useEffect(() => {
        if (rejectLeadStatus === FetchStatus.Success && rejectReasonId) {
            trackEvent(EventName.L2FLeadRejected, {rejectReasonId});
            removeMatch({accepted: false});
        } else if (rejectLeadStatus === FetchStatus.Error) {
            closeModal();
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.RejectLead,
                errorMessage: rejectLeadError.message,
            });
        }
    }, [rejectLeadStatus, closeModal, rejectLeadError.message, rejectReasonId, removeMatch, trackEvent]);

    const dismissModal = useCallback(() => {
        closeModal();
        setSelectedReason(null);
    }, [closeModal]);

    const onConfirmClick = useCallback(() => {
        rejectLead(Number(selectedReason));
    }, [rejectLead, selectedReason]);

    const onChangeReason: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        setSelectedReason(event.target.value);
    }, []);

    const rejectionReasons = useMemo(() => {
        if (!rejectReasonsData?.rejection_categories) {
            return [];
        }
        const allReasons: LeadToFleetService.RejectReason[] = [];
        rejectReasonsData.rejection_categories.forEach((category) => {
            category.reasons.forEach((reasons) => {
                allReasons.push(reasons);
            });
        });
        return allReasons;
    }, [rejectReasonsData]);

    return (
        <ResponsiveDialog
            title={i18n("auth.app.fleet.matches.reject_dialog.title")}
            isOpen={show}
            onRequestClose={dismissModal}
        >
            <Dialog.Content>
                <LoadingSpinner show={isRejectReasonsLoading} />
                {!isRejectReasonsLoading && rejectReasonsData?.rejection_categories.length !== 0 && (
                    <>
                        <p className="mb-4 text-sm font-semibold text-neutral-900">
                            {i18n("auth.app.fleet.matches.reject_dialog.select_reason_v2")}
                        </p>
                        <RadioGroup name="rejection-reasons" value={selectedReason ?? ""} onChange={onChangeReason}>
                            {rejectionReasons.map(({id, name}) => (
                                <Radio key={id} label={name} value={id.toString()} />
                            ))}
                        </RadioGroup>
                    </>
                )}
                {!isRejectReasonsLoading && !rejectReasonsData?.rejection_categories.length && (
                    <p className="text-red-500">{i18n("auth.app.fleet.matches.reject_dialog.no_data")}</p>
                )}
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={closeModal}>
                        {i18n("auth.app.fleet.matches.reject_dialog.cancel")}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={onConfirmClick}
                        disabled={isRejectReasonsLoading || !selectedReason}
                    >
                        {i18n("auth.app.fleet.matches.decline")}
                    </Button>
                </div>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};

export default RejectDialog;
