import {useCallback, useEffect, useState} from "react";

import {DEFAULT_MAP_CONFIG} from "common/geo/components/google-maps/constants";
import {MapHelper} from "common/geo/components/google-maps/MapHelper";
import {BBox, BoundsPadding, GoogleMapApi, Point} from "common/geo/components/google-maps/types";

import {Address} from "../../../types";

interface Props {
    googleMapsApi?: GoogleMapApi;
    pickup: Address;
    dropoffs: Address[];
    polylineLatsLngs?: Point[];
    setBounds: (bounds: BBox) => void;
    getBbox: () => BBox;
    geoLocation: GeolocationPosition | null;
}

export function useZoomLevel({
    googleMapsApi,
    pickup,
    dropoffs,
    polylineLatsLngs,
    setBounds,
    getBbox,
    geoLocation,
}: Props) {
    const [zoomLevel, setZoomLevel] = useState<number>(DEFAULT_MAP_CONFIG.zoom);

    const setAddressesOrGeoLocationIntoView = useCallback(() => {
        if (!googleMapsApi) {
            return;
        }
        const pickupPoint: Point[] = pickup.lat && pickup.lng ? [{lat: pickup.lat, lng: pickup.lng}] : [];
        const dropoffPoints = dropoffs
            .map<Partial<Point>>((dropoff) => ({
                lat: dropoff.lat,
                lng: dropoff.lng,
            }))
            .filter((point) => Boolean(point.lat && point.lng)) as Point[];
        const points: Point[] = [...pickupPoint, ...dropoffPoints, ...(polylineLatsLngs ?? [])];
        const mapPadding: BoundsPadding = 20;
        if (points.length) {
            MapHelper.fitBounds(googleMapsApi, points, mapPadding);
            setZoomLevel(MapHelper.getZoom(googleMapsApi));
            setBounds(getBbox());
        } else if (geoLocation?.coords.latitude && geoLocation?.coords.longitude) {
            MapHelper.fitBounds(
                googleMapsApi,
                [{lat: geoLocation.coords.latitude, lng: geoLocation.coords.longitude}],
                mapPadding,
            );
            MapHelper.setZoom(googleMapsApi, 10);
            setZoomLevel(MapHelper.getZoom(googleMapsApi));
            setBounds(getBbox());
        }
    }, [googleMapsApi, pickup, dropoffs, polylineLatsLngs, geoLocation, setBounds, getBbox]);

    useEffect(() => {
        setAddressesOrGeoLocationIntoView();
    }, [setAddressesOrGeoLocationIntoView]);

    return zoomLevel;
}
