import {useEffect, useMemo, useRef} from "react";

import {Point, Route} from "common/geo/components/google-maps/types";
import {useMapCommon} from "common/geo/components/google-maps/useMapCommon";

import {Address} from "../../../types";
import {useAddressMarkers} from "./useAddressMarkers";
import {useZoomLevel} from "./useZoomLevel";

interface Props {
    pickup: Address;
    dropoffs: Address[];
    routePolyline?: string;
    geoLocation: GeolocationPosition | null;
}

const ROUTE_POLYLINE_COLOR = "#5B68F6";
const ROUTE_POLYLINE_OPACITY = 1;
const ROUTE_POLYLINE_WEIGHT = 4;

export const useMap = ({pickup, dropoffs, routePolyline, geoLocation}: Props) => {
    const route = useRef<Route>();
    const {googleMapsApi, setBounds, onGoogleApiLoaded, getBbox} = useMapCommon();
    const markers = useAddressMarkers(pickup, dropoffs);

    const polylineLatsLngs = useMemo<Point[]>(() => {
        if (!googleMapsApi || !routePolyline) {
            return [];
        }
        return (
            googleMapsApi.maps.geometry.encoding
                .decodePath(routePolyline)
                ?.map((point) => ({lat: point.lat(), lng: point.lng()})) ?? []
        );
    }, [googleMapsApi, routePolyline]);

    useZoomLevel({
        googleMapsApi,
        pickup,
        dropoffs,
        polylineLatsLngs,
        setBounds,
        getBbox,
        geoLocation,
    });

    useEffect(() => {
        // Clear polylines
        route.current?.setMap(null);
        route.current = undefined;
        if (!googleMapsApi || !polylineLatsLngs || polylineLatsLngs.length < 0) {
            return;
        }

        route.current = new googleMapsApi.maps.Polyline({
            path: polylineLatsLngs,
            geodesic: true,
            strokeColor: ROUTE_POLYLINE_COLOR,
            strokeOpacity: ROUTE_POLYLINE_OPACITY,
            strokeWeight: ROUTE_POLYLINE_WEIGHT,
        });
        route.current?.setMap(googleMapsApi.map);
    }, [googleMapsApi, polylineLatsLngs]);

    return {onGoogleApiLoaded, markers, googleMapsApi};
};
