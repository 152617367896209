import {getVehiclePictureByType} from "@fleet/common/utils/vehicle/getVehiclePictureByType";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface Props {
    vehicleImageUrl?: string;
    vehicleModelBodyType: VehicleMarketplaceService.CarModelBodyType;
}

const VehiclePicture = ({vehicleImageUrl, vehicleModelBodyType}: Props) => (
    <img
        src={vehicleImageUrl ?? getVehiclePictureByType(vehicleModelBodyType)}
        alt="Vehicle"
        className="max-h-52 rounded object-cover"
    />
);

export default VehiclePicture;
